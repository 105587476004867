import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Route, Switch } from "react-router-dom";
import Dashboard from "../components/dashboard/Dashboard";
import DashboardAlt from "../components/dashboard-alt/DashboardAlt";
import DashboardNewDesign from "../components/dashboard-adserver/DashboardDesign";
// import DashboardNew from "../components/dashboard-adserver/Dashboard";
import DashboardNew from "../components/dashboard-new/Dashboard";
// import DashboardNew from "../components/dashboard-revamp/Dashboard";
import NavbarTop from "../components/navbar/NavbarTop";
import NavbarVertical from "../components/navbar/NavbarVertical";
import Footer from "../components/footer/Footer";
import loadable from "@loadable/component";
import AppContext from "../context/Context";
import ProductProvider from "../components/e-commerce/ProductProvider";
import SidePanelModal from "../components/side-panel/SidePanelModal";
import DirectDebitReport from "../components/reports/Donation/DirectDebitReport";
import { getconfigData, checkTokenAccess } from "./service";
import PublisherList from "../components/Publisher/PublisherList";
import showIcon from "../assets/img/dashboard-svgs/question-mark-svgrepo-com.svg";
import './layout.css'
import Help from "../components/Help/Help";
const DashboardRoutes = loadable(() => import("./DashboardRoutes"));

const DashboardLayout = ({ location }) => {
  const currentPath = window.location.pathname;
  const [showHelp, setShowHelp] = useState(false);
  const [showFaqOption, setShowFaqOption] = useState(false);
  const { isFluid, isTopNav } = useContext(AppContext);
  const [role, setRole] = useState(localStorage.getItem('role_id'))
  var forgott_style;
  if (window.location.pathname == "/ForgotPassword") {
    forgott_style = "none";
  }else if(window.location.pathname == "/login"){
    forgott_style = "none";
  }else if(window.location.pathname == "/pub/forgotPassword"){
    forgott_style = "none";
  }

  const clearLocalStorage = async ()=>{
    Object.keys(localStorage).forEach((key) => {
     // key !== "superadmin_mfa_verified" && 
      if (key !== "existingPublishers") {
        localStorage.removeItem(key);
      }
    });
    
    if (currentPath !== "/login" && currentPath != '/ForgotPassword'&& !currentPath.startsWith('/reset/')) {
      window.location.href = "/login";
    }else if(currentPath.startsWith('/reset/')){
      const token = currentPath.split('/').pop();
      window.location.href = `/reset/${token}`;
    }
   
  }

  useEffect(() => {
    tokenValidation()
  }, []);

  const tokenValidation = async () => {
    DashboardRoutes.preload();
    
    // const currentPath = window.location.pathname;
    if (currentPath !== "/login" && currentPath != '/ForgotPassword' && !currentPath.startsWith('/reset/')) {
      console.log('currentPath', currentPath);
      try {
        setConfiguration()
      } catch (error) {
        clearLocalStorage();
      }

    }
  }
  
  const setConfiguration = async () => {
    // const accessResponse = await checkTokenAccess();
    // console.log('accessResponse', accessResponse);
    // if (accessResponse.success == true) {
      getconfigData(localStorage.getItem("pub_id"))
          .then(async (data) => {
            localStorage.setItem(
              "channelConfig",
              JSON.stringify(data?.data?.channels)
            );
            localStorage.setItem(
              "publisherConfig",
              JSON.stringify(data?.data?.pub_config)
            );

            let publisherConfig = data?.data?.pub_config;
            const sessionExpiry = publisherConfig.find(
              (item) => item.key_name === "session_expiry"
            );
            const sessionTimeout = (sessionExpiry && sessionExpiry.key_value ? sessionExpiry.key_value : 3600) * 1000; // 1 hour in milliseconds
            async function checkSessionTimeout() {
              const sessionStartTime = localStorage.getItem("sessionStartTime");
              if (sessionStartTime) {
                if (
                  Date.now() - sessionStartTime > sessionTimeout
                ) {
                  if (currentPath !== "/login") {
                    localStorage.removeItem("channelConfig");
                    localStorage.removeItem("publisherConfig");
                    localStorage.removeItem("isLoged");
                    localStorage.removeItem("role_id");
                    localStorage.removeItem("pub_id");
                    localStorage.removeItem("currentPublisher");
                    await clearLocalStorage();
                  } // redirect to the login page
                  else{
                    await clearLocalStorage();
                  }
                }
              }
              else {
                await clearLocalStorage();
                // window.location.href = "/login";
              }
            }
            function resetTimer() {
              clearTimeout(checkSessionTimeout);
              localStorage.setItem("sessionStartTime", Date.now());
              setTimeout(checkSessionTimeout, sessionTimeout);
            }

            // Call resetTimer() whenever the user performs any activity that indicates user interaction
            document.addEventListener("mousemove", resetTimer.bind(null, true));
            document.addEventListener("keypress", resetTimer.bind(null, true));
            document.addEventListener("scroll", resetTimer.bind(null, true));
            if (localStorage.getItem("isInitialLogin")) {
              resetTimer();
              localStorage.removeItem("isInitialLogin");
            } else {
              await checkSessionTimeout();
              resetTimer();
            }
          })
          .catch(async (e) => {
            await clearLocalStorage();
            return false;
          });
    // } else {
    //   await clearLocalStorage();
    // }
    
  }
  
  // useEffect(() => {
  //   const currentPath = window.location.pathname;
  //   if (currentPath !== "/login") {
  //     getconfigData(localStorage.getItem("pub_id"))
  //       .then((data) => {
  //         localStorage.setItem(
  //           "channelConfig",
  //           JSON.stringify(data?.data?.channels)
  //         );
  //         localStorage.setItem(
  //           "publisherConfig",
  //           JSON.stringify(data?.data?.pub_config)
  //         );
  //       })
  //       .catch((e) => {
  //         console.error(e);
  //         return false;
  //       });
  //   }
  // }, [location]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if(location.pathname == '/login' || location.pathname.startsWith('/help/read_more/')){
     setShowFaqOption(false)
    }else{
      setTimeout(() => {
        setShowFaqOption(true)  
      }, 5000);

    }
  }, [location.pathname]);


  const toggleOverlay = () => {
   
    setShowHelp(!showHelp); // Toggle the Help component
  };
  let primaryDashboard =
    localStorage.getItem("role_id") == 7
      ? PublisherList
      : localStorage.getItem("role_id") == 13
        ? DirectDebitReport
        : DashboardNew;

  return (
    <div className={isFluid ? "container-fluid" : "container"}>
      {!isTopNav && <NavbarVertical />}
      <ProductProvider>
        <div className="content">
          <NavbarTop />
          {
            showFaqOption ?
            <div className="right-nav col-md-3 col-xl-3">
            <div className="side-nav" onClick={toggleOverlay}>
              {showHelp ? (
                <span className="back-btn">Back</span>
              ) : (
            <img src={showIcon} style={{width:'19px',height:'auto'}}title="Support" alt="Support" />
              )}
            </div>
          </div>:null
          }
      
          <Switch>
            <Route path="/" exact component={primaryDashboard} />
            <Route path="/dashboard-alt" exact component={DashboardAlt} />
            <Route path="/adserver-dashboard-design" exact component={DashboardNewDesign} />
            <Route path="/dashboard-new" exact component={DashboardNew} />
            <DashboardRoutes />
          </Switch>
          {showHelp && (
      <div className={`help-component ${showHelp ? 'show' : 'hidden'}`}>
        <Help />
      </div>
    )}
          <Footer forgott_style={forgott_style} />
        </div>
        {/* <SidePanelModal /> */}
      </ProductProvider>
    </div>
  );
};

DashboardLayout.propTypes = { location: PropTypes.object.isRequired };

export default DashboardLayout;

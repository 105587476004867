import React, { Fragment, useEffect, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Row, CustomInput, Label,FormGroup, Container } from 'reactstrap';
import PropTypes from 'prop-types';
import FalconCardHeader from '../common/FalconCardHeader';
import { toast } from 'react-toastify';
import { pubService } from './service';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter'; 
import { checkTokenAccess } from "../../layouts/service"; 
import Loader from '../common/Loader'
const Tables = ({ setRedirect,history }) => {
  //set state using usestate
  let [user, setUser] = useState([]);
  let [update, setUpdate] = useState(true);
  const [isLoading,setIsLoading]= useState(true)
  const [backupPublisherList, setBackupPublisherList] = useState([]);
 //render firstly when launch


  const hangleAtoZsort = (value) => {
   
    if (value) {

      let startsWithN = backupPublisherList.filter((res) =>
        res?.name?.toLowerCase().startsWith(value?.element?.toLowerCase())
      );
      setUser(startsWithN.length > 0 ? startsWithN : []);
   
    } else {
      setUser(backupPublisherList);
    }
  };
  useEffect( () => {
    const fetchData = async () => {
      try {
        const accessResponse = await checkTokenAccess();
        if (accessResponse.success === true) {
          const response = await pubService.getPublishersList(localStorage.getItem('pub_id'));
          if (response.success === true) {
            const datas = response.data;
            setIsLoading(false);
            setUser(datas);
            setBackupPublisherList(datas);
          }
        } else {
          await clearLocalStorage();
        }
      } catch (error) {
        setIsLoading(false);
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [update]);
//pagination
  const clearLocalStorage = async () => {
    Object.keys(localStorage).forEach((key) => {
      // key !== "superadmin_mfa_verified" && 
        localStorage.removeItem(key);
    });
    window.location.href = "/login";
  }

  let options = {
    custom: true,
    sizePerPage: 20,
    totalSize: user.length
  };
 //handler for deletion

  const deleteItem = (pubid) => {
    
    var r = window.confirm("Do you want to delete ?");
        if (r == true) {
          pubService.deletePublisher(pubid).then(response => {
              if (response.success == true) {
                 toast.success(`Publisher deleted successfully`);
                //  setIsLoading(true)
                 setTimeout(() => {
                  pubService.getPublishersList()
                  .then((response) => {
                    if (response.success === true) {
                      const datas = response.data;
                      // setIsLoading(false)
                       setUser(datas);
                    }
                  },
                    (error) => {
                      toast.error('Something went wrong ! try again');
                        setIsLoading(false)
                      return false;
                    });
                 // window.location.replace('/super-publisher/list');
                 }, 1000);
              } else {
                 toast.error('Something went wrong ! try again');
             }
           })
         }
  }

 
 //specifies the colmns of the table
 const columns = [
    {
        dataField: 'slno',
        text: 'Si.no',
        classes: 'border-0 align-middle',
        headerClasses: 'border-0',
        sort: true,
        align: 'left',
        headerAlign: 'left',
        footerAlign: 'left',
        footer: ''
    },
    {
        dataField: 'pubid',
        text: 'Publisher Id',
        classes: 'border-0 align-middle',
        headerClasses: 'border-0',
        sort: true,
        align: 'left',
        headerAlign: 'left',
        footerAlign: 'left',
        footer:'',
        // filter: textFilter()
      },
      {
        dataField: 'name',
        text: 'Name',
        classes: 'border-0 align-middle',
        headerClasses: 'border-0',
        align: 'left',
        headerAlign: 'left',
        footer :''
      },
      {
        dataField: 'email',
        text: 'Email',
        classes: 'border-0 align-middle',
        headerClasses: 'border-0',
        sort: true,
        align: 'left',
        headerAlign: 'left',
        footer :''
      },
      {
        dataField: 'cdn_type',
        text: 'CDN Type',
        classes: 'border-0 align-middle',
        headerClasses: 'border-0',
        sort: true,
        align: 'left',
        headerAlign: 'left',
        footer :''
      },
      
      {
        dataField: 'bucket_type',
        text: 'Bucket Type',
        classes: 'border-0 align-middle',
        headerClasses: 'border-0',
        sort: true,
        align: 'left',
        headerAlign: 'left',
        footer :''
      },
      {
        dataField: 'appid',
        text: 'Edit',
        classes: 'border-0 align-middle',
        headerClasses: 'border-0',
        sort: true,
        align: 'left',
        headerAlign: 'left',
        footer :'',
        footerAlign: 'left',
        formatter: (rowContent, row) => {
          return (
            <Fragment>
              <Link to={{ pathname: '/super-admin/publisher-edit', state: { pubid: row?.pubid} }}>
                <span style={{ padding: '2px' }}>
                  {/* <img src={editImg} style={{ width: '25px' }}/> */}
                  <FontAwesomeIcon icon="edit" />
                </span>
              </Link>
            </Fragment>
          );
        },
      }, 
      // {
      //   dataField: 'id',
      //   text: 'Delete',
      //   classes: 'border-0 align-middle',
      //   headerClasses: 'border-0',
      //   sort: true,
      //   align: 'left',
      //   headerAlign: 'left',
      //   footer :'',
      //   footerAlign: 'left',
      //   formatter: (rowContent, row) => {
      //     return (
      //       <Fragment>
      //         <div onClick={() => { deleteItem(row.pubid) }} style={{ cursor: 'pointer' }}>
      //           <span >
      //             {/* <img src={deleteImg} /> */}
      //             <FontAwesomeIcon icon="trash-alt" />
                  
      //           </span>
      //         </div>
      //       </Fragment>
      //     );

      //   },
      // }, 
      {
        dataField: 'id',
        text: 'Impersonate',
        classes: 'border-0 align-middle',
        headerClasses: 'border-0',
        sort: true,
        align: 'left',
        headerAlign: 'left',
        footer :'',
        footerAlign: 'left',
        hidden: !(localStorage.getItem("role_id")==7),
        formatter: (rowContent, row) => {
          return (
            <Fragment>
              <div onClick={() => { impersonatePublisher(row.pubid) }} style={{ cursor: 'pointer' }}>
                <span >
                  {/* <img src={deleteImg} /> */}
                  <FontAwesomeIcon icon="user" />
                  
                </span>
              </div>
            </Fragment>
          );

        },
      },
    ];

  const handleNextPage = ({ page, onPageChange }) => () => {
    onPageChange(page + 1);
  };
  //pagination
  const handlePrevPage = ({ page, onPageChange }) => () => {
    onPageChange(page - 1);
  };

  const impersonatePublisher = (pubid) =>{
    pubService.impersonatePublisher(pubid).then((response)=>{
      let { 
        role_id,
        email,
        name,
        bucket_type,
        pub_config,
        channels,
        pubid,
        timezone,
        currency,
        config
      } =response.data;
      let channel_config =channels.length >0 ? channels : "[]";
      // pub_config= pub_config.length > 0 ? pub_config :"[]" 
      localStorage.setItem("impersonated","yes");
      let superadmin={
        role_id:localStorage.getItem("role_id"),
        isRTL: localStorage.getItem("isRTL"),
        user_name:localStorage.getItem("user_name"),
        channelConfig:localStorage.getItem("channelConfig") ? localStorage.getItem("channelConfig") :[],
        favouriteItems:localStorage.getItem("favouriteItems"),
        publisherConfig:localStorage.getItem("publisherConfig"),
        bucketType:localStorage.getItem("bucketType"),
        isFluid:localStorage.getItem("isFluid"),
        currentChannel:localStorage.getItem("currentChannel"),
        isTopNav:localStorage.getItem("isTopNav"),
        pub_id:localStorage.getItem("pub_id"),
        isDark:localStorage.getItem("isDark"),
        isNavbarVerticalCollapsed:localStorage.getItem("isNavbarVerticalCollapsed"),
        isNavbarVerticalCollapsed:localStorage.getItem("isNavbarVerticalCollapsed"),
        user_email:localStorage.getItem("user_email"),
        products:localStorage.getItem("products"),
        shoppingCart:localStorage.getItem("shoppingCart"),
      }
      
      localStorage.setItem("superadmin",JSON.stringify(superadmin));
      localStorage.setItem("role_id",role_id);
      localStorage.setItem("user_name",name);
      localStorage.setItem("channelConfig",JSON.stringify(channel_config));
      if(pub_config && pub_config.length>0){
      localStorage.setItem("publisherConfig",JSON.stringify(pub_config));
      }else{
        let pub;
        localStorage.setItem("publisherConfig",pub);
      }
      if(response.data?.channels[0]?.channel_id){
        localStorage.setItem("currentChannel",response.data?.channels[0]?.channel_id);
      }else{
        localStorage.removeItem("currentChannel");
      }

      localStorage.setItem("bucketType",bucket_type);
      localStorage.setItem("user_email",email);
      localStorage.setItem("pub_id", pubid);
      localStorage.setItem("currentPublisher", pubid);
      localStorage.setItem("timezone", timezone);
      localStorage.setItem("currency", JSON.stringify(currency));
      localStorage.setItem("config", JSON.stringify(config)); 
      toast.success(`Impersonated as ${email}`)
      window.location.href= '/'
    })
    }
    
  return (
    <Fragment>
            <div className="bread-crumbs-container col-lg-12 mt-0 top-head-height  body-inner-wrapper p-0 border-0">
        <div className="bread-crumbs">
          <div className="heading pl-1 pt-2 ml-3 df">
            <h1 className="mt-1">Publishers List</h1>
          </div>
          <div className="mr-3 pr-3">
            <Link
              to={{
                pathname: "/super-admin/publisher-add",
              }}
            >
              <Button color="primary" >Create</Button></Link>
          </div>
        </div>
      </div>
      <Container className="body-inner-wrapper devider"></Container>
      <Container fluid className="list-table body-inner-wrapper p-0 ">

      <Row noGutters>
      {/* <Col lg={3} className="pr-lg-2" >
        <FalconCardHeader title="App Management" light={false} />
        <h3 style={{margin:'2rem 0 1rem 0'}} >Publishers List</h3>
        </Col>
        <Col lg={9} className="pr-lg-2" style={{textAlign: 'end',}}   >
        <Link to='/super-admin/publisher-add'>
        <Button style={{margin:'2rem 0 1rem 0'}}>ADD</Button>
        </Link>
        </Col> */}
            {!isLoading && <Container className="body-inner-wrapper ml-0">
        <Row>
          <Col lg={9}>
            <nav aria-label="Page navigation example">
              <ul className="pagination">
                {[
                  "A",
                  "B",
                  "C",
                  "D",
                  "E",
                  "F",
                  "G",
                  "H",
                  "I",
                  "J",
                  "K",
                  "L",
                  "M",
                  "N",
                  "O",
                  "P",
                  "Q",
                  "R",
                  "S",
                  "T",
                  "U",
                  "V",
                  "W",
                  "X",
                  "Y",
                  "Z",
                ].map((element, index) => {
                  return (
                    <li className="page-item" key={index}>
                      <span
                        className="page-link"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          hangleAtoZsort({ element });
                        }}
                      >
                        {element}
                      </span>
                    </li>
                  );
                })}
                <li className="page-item">
                  <span
                    className="page-link"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      hangleAtoZsort("");
                    }}
                  >
                    RESET
                  </span>
                </li>
              </ul>
            </nav>
          </Col>

        </Row>

      </Container>}
      {isLoading ? <div className='mt-6 pt-6 text-center entire-width'> <Loader /> </div> :user.length !== 0 ? <Fragment>
        <Col sm={12} className="mb-12">
            <CardBody className="p-0">
              <PaginationProvider pagination={paginationFactory(options)}>
                {({ paginationProps, paginationTableProps }) => {
                  const lastIndex = paginationProps.page * paginationProps.sizePerPage;
                  return (
                     <Fragment> 
                      <div className="table-responsive">
                      <BootstrapTable
                                                                // bootstrap4={true}
                                                                id="excel"
                                                                keyField="id"
                                                                data={user.map((element, index) => { return { ...element, slno: index + 1 } })}
                                                                columns={columns}
                                                                bordered={true}
                                                                condensed={true}
                                                                hover={true}
                                                                tabIndexCell={true}
                                                                striped={true}
                                                                search={true}
                                                                expanded={true}
                                                                expandable={true}
                                                                searchable={true}
                                                                footer={true}
                                                                // filter={ filterFactory() }
                                                                classes="table-dashboard table-sm fs--1 border-bottom border-200 mb-0 table-dashboard-th-nowrap"
                                                                rowClasses="table-content-row btn-reveal-trigger border-top border-200"
                                                                headerClasses="table-content-row bg-200 text-900 border-y border-200"
                                                                {...paginationTableProps}
                                                                />
                      </div>
                      <Row noGutters className="px-1 py-3">
              <Col xs="auto" className="pr-3">
                <Button
                  color={paginationProps.page === 1 ? 'light' : 'primary'}
                  size="sm"
                  onClick={handlePrevPage(paginationProps)}
                  disabled={paginationProps.page === 1}
                  className="px-4"
                >Previous
                </Button>
                <Button
                  color={lastIndex >= paginationProps.totalSize ? 'light' : 'primary'}
                  size="sm"
                  onClick={handleNextPage(paginationProps)}
                  disabled={lastIndex >= paginationProps.totalSize}
                  className="px-4 ml-2"
                >Next
                </Button>
              </Col>
            </Row>
                    </Fragment>
                  );
                }}
              </PaginationProvider>
            </CardBody>
        </Col>
        </Fragment> :
         <div className='my-4  entire-width text-center' >
                    <h5 ><span><strong>No Publisher Data's</strong> Available!</span></h5></div>}
      </Row>
      </Container>
    </Fragment >
  );
};
Tables.propTypes = {
  setRedirect: PropTypes.func.isRequired,
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};
Tables.defaultProps = {
  layout: 'basic',
  hasLabel: false
};
export default Tables;

import React, { useState, useEffect } from "react";
import { Fragment } from "react";
import { CardBody, Col, Row, Card, Button, CardHeader } from "reactstrap";
import CardComponent from "./CardComponent/Card";
import PiChart from "./CardComponent/PiCharts";
import Bargraph from "./CardComponent/Bargraph";
import PoinGraph from "./CardComponent/Poingraph";
import { Tabs, Tab } from "react-bootstrap";
import { Link } from "react-router-dom";
import purchases from "../../data/dashboard/purchaseList";
import ButtonIcon from "../common/ButtonIcon";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import FalconCardHeader from "../common/FalconCardHeader";
import BootstrapTable from "react-bootstrap-table-next";
import WeeklySales from "../dashboard-alt/WeeklySales";
import weeklySales from "../../data/dashboard/weeklySales";
import channelIcon from "../../assets/img/dashboard-svgs/channels-svgrepo.svg";
import liveEventIcon from "../../assets/img/dashboard-svgs/play-stream-svgrepo-com.svg";
import videoIcon from "../../assets/img/dashboard-svgs/video-recorder-svgrepo-com.svg";
import showIcon from "../../assets/img/dashboard-svgs/television-tv-svgrepo-com.svg";
import usersIcon from "../../assets/img/dashboard-svgs/users-more-svgrepo-com.svg";
import newSubIcon from "../../assets/img/dashboard-svgs/rss-updates-subscription-svgrepo-com.svg";
import activeSubIcon from "../../assets/img/dashboard-svgs/membership-member-subscription-id-card-svgrepo-com.svg";
import revenueIcon from "../../assets/img/dashboard-svgs/sales-amount-svgrepo-com.svg";
import renewalIcon from "../../assets/img/dashboard-svgs/credit-card-refresh-svgrepo-com.svg";
import requestsIcon from "../../assets/img/dashboard-svgs/request-approval-svgrepo-com.svg";
import impressionsIcon from "../../assets/img/dashboard-svgs/tap-back-svgrepo-com.svg";
import fillrateIcon from "../../assets/img/dashboard-svgs/discounts-percentage-label-on-calendar-daily-page-svgrepo-com.svg";
import revenueIcon2 from "../../assets/img/dashboard-svgs/growth.svg";
import installedIcon from "../../assets/img/dashboard-svgs/install-svgrepo-com.svg";
import activeUserIcon from "../../assets/img/dashboard-svgs/user-multiple-users-svgrepo-com.svg";
import totalViewersIcon from "../../assets/img/dashboard-svgs/page-statistics-svgrepo-com.svg";
import viewedMinutesIcon from "../../assets/img/dashboard-svgs/time-watch-svgrepo-com.svg";
import live from "../../assets/img/dashboard-svgs/view-alt-svgrepo-com.svg";
import merchantIcon from "../../assets/img/dashboard-png/cashier.png";
import productIcon from "../../assets/img/dashboard-png/products.png";
import totalProductIcons from "../../assets/img/dashboard-svgs/total-products.png";
import monthlyProductIcon from "../../assets/img/dashboard-svgs/monthly-products.png";
import weeklyProductIcons from "../../assets/img/dashboard-svgs/weekly-products.png";
import monthlyOrders from "../../assets/img/dashboard-svgs/monthly-orders.png";
import weeklyOrders from "../../assets/img/dashboard-svgs/weekly-orders.png";
import adRevenue from '../../assets/img/dashboard-svgs/money-svgrepo-com.svg'
import monthlyRevenueIcon from '../../assets/img/dashboard-svgs/sales-amount-svgrepo-com.svg'
import totalRevenueIcon from '../../assets/img/dashboard-svgs/total-revenue.png'

import { services } from "./Service";
import { service } from "../reports/service";
const Dashboard = (props) => {
  const [channelsCount, setChannelsCount] = useState(0);
  const [videosCount, setVideosCount] = useState(0);
  const [counts, setCounts] = useState({});
  const [showsCount, setShowsCount] = useState(0);
  const [users, setUsers] = useState(0);
  const [totalActiveSubscriptions, setTotalActiveSubscriptions] = useState(0);
  const [newSubscriptions, setNewSubscriptions] = useState(0);
  const [montlyRenewal, setMontlyRenewal] = useState(0);
  const [monthlyRevenue, setMonthlyRevenue] = useState(0);
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [monthlyEcommerceRevenue, setMonthlyEcommerceRevenue] = useState(0);

  const [requestCount, setRequestCount] = useState(0);
  const [impressionsCount, setImpressionsCount] = useState(0);
  const [fillRate, setFillRate] = useState(0);
  const [revenue, setRevenue] = useState(0);
  const [totalViewers, setTotalViewers] = useState(0);
  const [totalActiveUsers, setTotalActiveUsers] = useState(0);
  const [installedCount, setInstalledCount] = useState(0);
  const [todayViewedMinutes, setTodayViwedMinutes] = useState(0);
  const [showLiveCount, setShowLiveCount] = useState(0);
  const [external, setExternal] = useState(true);
  const [contentPartner, setContentPartner] = useState(true);

  const [totalSubscriptionsGraph, setTotalSubscriptionsGraph] = useState({
    xAxis: [],
    yAxis: [],
  });
  const [newSubscriptionsGraph, setNewSubscriptionsGraph] = useState({
    xAxis: [],
    yAxis: [],
  });
  const [cancellationsData, setCancellations] = useState({
    xAxis: [],
    yAxis: [],
  });
  const [revenueReportGraphData, setrevenueReportGraphData] = useState({
    xAxis: [],
    yAxis: [],
  });
  const [fillRateGraphData, setFillRateGraphData] = useState({
    xAxis: [],
    yAxisFilrate: [],
  });

  const [activeUsersReportGraphData, setActiveUsersReportGraphData] = useState({
    xAxis: [],
    yAxis: [],
  });
  const [subscriptonRevenue, setSubscriptionRevenue] = useState([]);
  const [renewedSubscriptonRevenue, setRenewedSubscriptionRevenue] = useState(
    []
  );
  const [popularShows, setPopularShows] = useState([]);
  const [popularVideos, setPopularVideos] = useState([]);
  const [usersPerDevice, setUsersPerDevice] = useState([]);
  const [usersPerCounry, setUsersPerCountry] = useState([]);
  const [productsPerCategory, setProductsPerCategory] = useState([]);

  const [totalOrders, setTotalOrders] = useState(0);
  const [totalMonthlyOrders, setTotalMonthlyOrders] = useState(0);
  const [popularProducts, setPopularProducts] = useState([]);
  const [monthlyProductViews, setmonthlyProductViews] = useState(0);
  const [weeklyProductView, setWeeklyProductViews] = useState(0);
  const [ecomCounts, setEcomCounts] = useState({
    productViews: 0,
    merchants: 0,
    products: 0,
    weekly_order: 0,
    weekly_ecom_revenue: 0,
  });

  const [ecomTopViews, setEcomTopViews] = useState({
    products: [],
    merchants: [],
    categories: [],
  });

  const [ecomTopPurchased, setEcomTopPurchased] = useState({
    products: [],
    merchants: [],
    categories: [],
  });

  const [ecomOrdersGraph, setEcomOrdersGraph] = useState({
    xAxis: [],
    yAxis: [],
  });

  const [ecomGraphs, setEcomGraphs] = useState({
    xAxis: [],
    yAxis: [],
  });

  const [
    projectedSubscriptonRevenue,
    setProjectedSubscriptionRevenue,
  ] = useState([]);
  const [
    vodanalyticsReportGraphData,
    setvodanalyticsReportGraphData,
  ] = useState({ xAxis: [], yAxis: [] });
  const [
    vodanalyticsViewReportGraphData,
    setvodanalyticsViewReportGraphData,
  ] = useState({ xAxisView: [], yAxisView: [] });

  const [key, setKey] = useState("Duration");

  const [defaultChannel, setDefaultChannel] = useState(null);

  function channelFilterColumnConfig() {
    var publisherChannels
    if (localStorage.getItem("channelConfig") && typeof localStorage.getItem("channelConfig") !== 'undefined' && (localStorage.getItem("channelConfig") !== 'undefined') && localStorage.getItem("channelConfig") !== "[]") {
      publisherChannels = JSON.parse(localStorage.getItem("channelConfig"));
    } else {
      publisherChannels = []
    }
    // var publisherChannels = JSON.parse(localStorage.getItem("channelConfig"));
    const channelConfig = publisherChannels;

    if (!localStorage.getItem("currentChannel") && !localStorage.getItem("influencer_id")) {
      setDefaultChannel(channelConfig[0]);
    } else {
      publisherChannels && publisherChannels !== "[]" &&
        publisherChannels.map((item) => {
          if (localStorage.getItem("currentChannel") == item.channel_id) {
            setDefaultChannel(item);
          }
        });
    }
  }
  // Table Datas
  const options = {
    custom: true,
    sizePerPage: 10,
    totalSize: usersPerCounry.length,
  };

  const handleNextPage = ({ page, onPageChange }) => () => {
    onPageChange(page + 1);
  };

  const handlePrevPage = ({ page, onPageChange }) => () => {
    onPageChange(page - 1);
  };

  const CustomTotal = ({ sizePerPage, totalSize, page, lastIndex }) => (
    <span>
      {(page - 1) * sizePerPage + 1} to{" "}
      {lastIndex > totalSize ? totalSize : lastIndex} of {totalSize}
    </span>
  );
  const columns = [
    {
      dataField: "slNo",
      text: "#",
      classes: "border-0 align-middle",
      headerClasses: "border-0",
    },

    {
      dataField: "country",
      text: "Country",
      classes: "border-0 align-middle",
      headerClasses: "border-0",
      sort: true,
    },
    {
      dataField: "count",
      text: "Count",
      classes: "border-0 align-middle",
      headerClasses: "border-0",
      sort: true,
    },
  ];

  ///   API CALLS ///

  const piChartColors = [
    "#2c7be5",
    "#2caa03",
    "#fc0303",
    "#000000",
    "#fcba03",
    "#27bcfd",
  ];

  useEffect(() => {
    var role_id = localStorage.getItem("role_id");
    if (role_id == 6) {
      setExternal(false);
    } else if (role_id == 8) {
      setContentPartner(false);
    }
    if (defaultChannel) {
      services
        .getCount(
          localStorage.getItem("pub_id"),
          localStorage.getItem("role_id"),
          defaultChannel.channel_id
        )
        .then((response) => {
          // console.log(response, "count");
          {
            response &&
              setVideosCount(
                response?.data[0] && response?.data[0]?.videos_count
              );
            response &&
              setChannelsCount(
                response?.data[0] && response.data[0]?.channels_count
              );
            response &&
              setShowsCount(response?.data[0] && response.data[0]?.shows_count);
            response &&
              setUsers(response?.data[0] && response.data[0]?.users_count);
            response &&
              setTotalActiveSubscriptions(
                response?.data[0] &&
                response.data[0]?.total_active_subscriptions
              );
            response &&
              setNewSubscriptions(
                response?.data[0] && response.data[0]?.new_subscriptions
              );
            response &&
              setMontlyRenewal(
                response?.data[0] && response.data[0]?.monthly_renewal
              );
            response &&
              setMonthlyRevenue(
                response?.data[0] && response.data[0]?.monthly_revenue
              );

            if (response) {
              setTotalOrders(response?.data?.[0]?.total_order);
              setTotalMonthlyOrders(response?.data?.[0]?.monthly_order);
              setMonthlyEcommerceRevenue(
                response?.data?.[0]?.ecommerce_monthly_revenue
              );
              setTotalRevenue(response?.data?.[0]?.total_revenue);

              setEcomCounts({
                ...ecomCounts,
                products: response?.data?.[0]?.product_count,
                merchants: response?.data?.[0]?.merchant_count,
                weekly_order: response?.data?.[0]?.weekly_order,
                weekly_ecom_revenue:
                  response?.data?.[0]?.ecommerce_weekly_revenue,
              });
            }
          }
        });
      // services.getEcommerceCount().then((response)=>{
      //   if(response.success==true){
      //   console.log("response",response.data[0].total_order,response.data[0].monthly_order)

      //   }
      // })

      services
        .getGraphData(
          localStorage.getItem("pub_id"),
          localStorage.getItem("role_id"),
          defaultChannel.channel_id
        )
        .then((response) => {
          // console.log(response, "graph datatatatat");

          {
            let xAxis = [];
            let yAxis = [];
            let id = 0;
            response.total_subscriptions &&
              response.total_subscriptions.map((element) => {
                yAxis.push(element.count);
                xAxis.push(element.day.slice(0, 10));
              });
            setTotalSubscriptionsGraph({
              xAxis,
              yAxis,
            });
          }

          {
            let xAxis = [];
            let yAxis = [];
            let id = 0;
            response.new_subscriptions &&
              response.new_subscriptions.map((element) => {
                yAxis.push(element.total);
                xAxis.push(element.day.slice(0, 10));
              });
            setNewSubscriptionsGraph({
              xAxis,
              yAxis,
            });
          }

          {
            let xAxis = [];
            let yAxis = [];
            let id = 0;
            response.cancelled_subscriptions &&
              response.cancelled_subscriptions.map((element) => {
                yAxis.push(element.total);
                xAxis.push(element.day.slice(0, 10));
              });
            setCancellations({
              xAxis,
              yAxis,
            });
          }

          //this is the title of the graph data like ['title',YOUR_TITLE_NAME]
          {
            let graphData = [["title", "Subscription"]];
            response.subscription_revenue &&
              response.subscription_revenue.map((element) => {
                graphData.push([element.day, element.total]);
              });
            setSubscriptionRevenue(graphData);
          }

          {
            let graphData = [["title", "Revenue"]]; //this is the title of the graph data like ['title',YOUR_TITLE_NAME]
            {
              response.renewed_subscriptions &&
                response.renewed_subscriptions.map((element) => {
                  graphData.push([element.day, element.total]);
                });
            }
            // console.log("setRenewedSubscriptionRevenueGraphData", graphData);
            setRenewedSubscriptionRevenue(graphData);
          }

          {
            let graphData = [["title", "Revenue"]]; //this is the title of the graph data like ['title',YOUR_TITLE_NAME]
            {
              response.projected_subscription_revenue &&
                response.projected_subscription_revenue.map((element) => {
                  graphData.push([element.day, element.total]);
                });
            }
            //console.log("setProjectedSubscriptionRevenuegraphData", graphData);
            setProjectedSubscriptionRevenue(graphData);
          }
        });

      services.getEcommerceOrderGraph().then((response) => {
        if (response && response?.success) {
          let xAxis = [];
          let yAxis = [];
          let id = 0;
          response.data.map((element) => {
            yAxis.push(element.price);
            xAxis.push(element.order_date);
          });
          setEcomOrdersGraph({ xAxis, yAxis });
        }
      });
      services.getEcommerceProductViewCountWeekly().then((response) => {
        if (response && response?.success) {
          setWeeklyProductViews(response?.data?.[0]?.count);
        }
      });
      services.getProductsPerCategory().then((response) => {
        console.log("response cat", response);
        let data = [];

        {
          let graphData = [["title", "Genre"]];

          response.data &&
            response.data.map((element, index) => {
              if (element.category_name) {
                let itemArr = [];
                itemArr.push(element.category_name);
                itemArr.push(element.count);
                graphData.push(itemArr);
              }
            });
          setProductsPerCategory(graphData);
        }
      });
      services
        .getUesrsPerCountry(
          localStorage.getItem("pub_id"),
          defaultChannel.channel_id
        )
        .then((response) => {
          let data = [];
          // let xAxis = [];
          // let yAxis = [];
          // {
          //     response.data && response.data.map((element, index) => {
          //         if(element.country) {
          //             yAxis.push(element.user_count)
          //             xAxis.push(element.country)
          //         }
          //     })
          //     setUsersPerCountry({
          //                 xAxis,
          //                 yAxis
          //             })
          // }

          {
            let graphData = [["title", "Country"]];
            // response.subscription_revenue && response.subscription_revenue.map(element => {
            //     graphData.push([element.day, element.total])
            // })
            response.data &&
              response.data.map((element, index) => {
                if (element.country) {
                  let itemArr = [];
                  itemArr.push(element.country);
                  itemArr.push(element.user_count);
                  graphData.push(itemArr);
                }
              });
            //  console.log("setUsersPerCountryGraphData", graphData);
            setUsersPerCountry(graphData);
          }

          // {
          //     let xAxis = [];
          //     let yAxis = [];
          //     let id = 0;
          //     response.data && response.data.map(element => {
          //         yAxis.push(element.user_count)
          //         xAxis.push(element.country)
          //     })
          //     setUsersPerCountry({
          //         xAxis,
          //         yAxis
          //     })

          // }
          // setUsersPerCountry({
          //             xAxis,
          //             yAxis
          //         })
          // setUsersPerCountry(data)
        });
      services.getEcommerceTopViewed().then(
        //{ id: 1, value: 53, name: 'KISS Season 2:Kiss S02 Ep 12', color: '#2c7be5' },
        (response) => {
          let productData = [];

          {
            response &&
              response?.data &&
              response.data.map((element, index) => {
                productData.push({
                  id: index + 1,
                  value: parseInt(element.count),
                  name: element.product_name,
                  color: piChartColors[index],
                });
              });
          }
          setPopularProducts(productData);
        }
      );
      services.getEcommerceProductViewCount().then((response) => {
        if (response && response?.success) {
          setmonthlyProductViews(response?.data?.[0]?.count);
        }
      });
      services.getEcommerceWeeklyProductViewGraph().then((response) => {
        if (response && response?.success) {
          let xAxis = [];
          let yAxis = [];
          let id = 0;
          response.data.map((element) => {
            yAxis.push(element.count);
            xAxis.push(element.filter_timestamp.value);
          });
          xAxis.reverse();
          yAxis.reverse();
          setEcomGraphs({ xAxis, yAxis });
        }
      });
      services
        .getTopViewed(
          localStorage.getItem("pub_id"),
          localStorage.getItem("role_id"),
          defaultChannel.channel_id
        )
        .then(
          //{ id: 1, value: 53, name: 'KISS Season 2:Kiss S02 Ep 12', color: '#2c7be5' },
          (response) => {
            let videosData = [];
            let showsData = [];
            {
              response &&
                response?.videos &&
                response.videos.map((element, index) => {
                  videosData.push({
                    id: index + 1,
                    value: parseInt(element.watch_count),
                    name: element.title,
                    color: piChartColors[index],
                  });
                });
              response?.shows &&
                response.shows.map((element, index) => {
                  showsData.push({
                    id: index + 1,
                    value: parseInt(element.watch_count),
                    name: element.title,
                    color: piChartColors[index],
                  });
                });
            }
            setPopularShows(showsData);
            setPopularVideos(videosData);
          }
        );

      services
        .getUesrsPerDevice(
          localStorage.getItem("pub_id"),
          localStorage.getItem("role_id"),
          defaultChannel.channel_id
        )
        .then((response) => {
          let data = [];
          //console.log(response.data);
          if (response.data) {
            if (response.data[0]) {
              var publisherName = response.data[0].publisherName;
              let index = 0;
              response.data.map((item) => {
                if (publisherName === item.publisherName) {
                  data.push({
                    id: index + 1,
                    value: parseInt(item?.user_count),
                    name: item?.appName,
                    color: piChartColors[index],
                  });
                  index = index + 1;
                }
              });
            }
          }
          //{ id: 1, value: 53, name: 'KISS Season 2:Kiss S02 Ep 12', color: '#2c7be5' },

          setUsersPerDevice(data);
          // console.log(data, "user by dev");
        });

      services
        .avodRevenueReportGraph(defaultChannel.channel_id)
        .then((response) => {
          let xAxis = [];
          let yAxis = [];
          let xAxisFilrate = [];
          let yAxisFilrate = [];

          {
            response.data &&
              response.data.map((element) => {
                yAxis.push(element.cpm);
                xAxis.push(element.request_timestamp);
                var fillrate = (
                  (element.totalImpression / element.totalRequest) *
                  100
                ).toFixed(2);
                console.log(
                  "element.totalImpression",
                  element.totalImpression,
                  "element.totalRequest",
                  element.totalRequest
                );
                yAxisFilrate.push(fillrate);
              });
          }
          console.log("yAxisFilrate", yAxisFilrate);
          setrevenueReportGraphData({
            xAxis,
            yAxis,
          });
          setFillRateGraphData({
            xAxis,
            yAxisFilrate,
          });
        });

      services
        .getActiveUsersReportGraph(
          localStorage.getItem("pub_id"),
          defaultChannel.channel_id
        )
        .then((response) => {
          let xAxis = [];
          let yAxis = [];

          {
            response.data &&
              response.data.map((element) => {
                yAxis.push(element.user_count);
                xAxis.push(element.filter_timestamp);
              });
            yAxis = yAxis.reverse();
            xAxis = xAxis.reverse();
          }

          setActiveUsersReportGraphData({
            xAxis,
            yAxis,
          });
        });

      services
        .VodAnalyticsReportGraph(
          localStorage.getItem("pub_id"),
          defaultChannel.channel_id
        )
        .then((response) => {
          //  console.log("..........response  grapgh jjjj", response);
          let xAxis = [];
          let yAxis = [];
          let xAxisView = [];
          let yAxisView = [];

          {
            response.data &&
              response.data.map((element) => {
                yAxis.push(element.duration / 60);
                xAxis.push(element.filter_timestamp);
                yAxisView.push(element.video_views);
                xAxisView.push(element.filter_timestamp);
              });
            yAxis = yAxis.reverse();
            xAxis = xAxis.reverse();
            yAxisView = yAxisView.reverse();
            xAxisView = xAxisView.reverse();
          }

          setvodanalyticsReportGraphData({
            xAxis,
            yAxis,
          });
          setvodanalyticsViewReportGraphData({
            xAxisView,
            yAxisView,
          });
        });

      services
        .getRevenueData(
          localStorage.getItem("pub_id"),
          defaultChannel.channel_id
        )
        .then((response) => {
          {
            response && setRequestCount(response.requests);
            setImpressionsCount(response.impressions);
            setFillRate(response.fillRate);
            setRevenue(response.revenue);
          }
        });

      services.getEcommerceTopView().then((response) => {
        let products = [];
        let merchants = [];
        let categories = [];

        if (response) {
          response.products &&
            response.products.map((element, index) => {
              products.push({
                id: index + 1,
                value: parseInt(element.view_count),
                name: element.product_name,
                color: piChartColors[index],
              });
            });
          response.categories &&
            response.categories.map((element, index) => {
              categories.push({
                id: index + 1,
                value: parseInt(element.view_count),
                name: element.category_name,
                color: piChartColors[index],
              });
            });
          response.merchants &&
            response.merchants.map((element, index) => {
              merchants.push({
                id: index + 1,
                value: parseInt(element.view_count),
                name: element.merchant_name,
                color: piChartColors[index],
              });
            });

          setEcomTopViews({
            products,
            merchants,
            categories,
          });
        }
      });

      services.getEcommerceTopBought().then((response) => {
        let products = [];
        let merchants = [];
        let categories = [];

        if (response) {
          response.bought_product.map((element, index) => {
            products.push({
              id: index + 1,
              value: parseInt(element.product_count),
              name: element.product_name,
              color: piChartColors[index],
            });
          });
          response.bought_categories.map((element, index) => {
            categories.push({
              id: index + 1,
              value: parseInt(element.product_count),
              name: element.category_name,
              color: piChartColors[index],
            });
          });
          response.bought_merchant.map((element, index) => {
            merchants.push({
              id: index + 1,
              value: parseInt(element.product_count),
              name: element.merchant_name,
              color: piChartColors[index],
            });
          });

          setEcomTopPurchased({
            products,
            merchants,
            categories,
          });

          console.log(products, categories, merchants);
        }
      });

      services
        .getActiveUsers(
          localStorage.getItem("pub_id"),
          defaultChannel.channel_id
        )
        .then((response) => {
          {
            response && setTotalActiveUsers(response.user_count);
          }
        });

      services
        .getInstallationsToday(
          localStorage.getItem("pub_id"),
          defaultChannel.channel_id
        )
        .then((response) => {
          {
            // console.log(response,'new user');
            response && setInstalledCount(response?.data?.[0]?.count);
          }
        });


      services
        .getShowLiveCount(
          localStorage.getItem("pub_id"),
          defaultChannel.channel_id
        )
        .then((response) => {
          {
            setShowLiveCount(response.data)
          }

        });



      services
        .getTotalViewers_minutes(
          localStorage.getItem("pub_id"),
          defaultChannel.channel_id
        )
        .then((response) => {
          {
            response && setTotalViewers(response?.video_views);
            setTodayViwedMinutes(
              response.total_duration
                ? (response.total_duration / 60).toFixed(1)
                : null
            );
          }
        });
    }
  }, [defaultChannel]);


  useEffect(() => {
    channelFilterColumnConfig();
  }, []);

  const getPublisherConfigInfo = keyName => {
    let response = false;
    if (localStorage.getItem("publisherConfig") && (typeof localStorage.getItem("publisherConfig") !== 'undefined' && (localStorage.getItem("publisherConfig") !== 'undefined'))) {
      var publisherConfig = JSON.parse(localStorage.getItem("publisherConfig"));
      if (publisherConfig) {
        let configInfo = publisherConfig.filter(i => keyName == i.key_name);
        response = configInfo[0] && configInfo[0].key_value;
        return response;
      }
    }
    return response;
  }

  const productColors = ["#738194"];

  return (
    <Fragment>
      <div class="row mt-6">
        {getPublisherConfigInfo("ecom_publisher") != "True" && external ? (
          <div class="col-xl-3 col-lg-6 col-sm-6">
            <div class="widget-stat card" style={{ height: '140px' }}>
              <div class="card-body p-4">
                <div class="media ai-icon">
                  <span class="mr-3 bgl-success text-success">
                    <img src={videoIcon} width="50" height="50" />
                  </span>
                  <div class="media-body mbody">
                    <p class="mb-1" style={{ textAlign: "center" }}>
                      Videos
                    </p>
                    <h4 class="mb-0" style={{ textAlign: "center" }}>
                      {parseInt(videosCount).toLocaleString("en-IN")}
                    </h4>
                  </div>
                  {/* <div class="media-body display">
                    <Link to="videos/list">
                      <p class="mb-1">See All</p>
                    </Link>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {getPublisherConfigInfo("ecom_publisher") != "True" && external ? (
          <>
            {" "}
            <div class="col-xl-3 col-lg-6 col-sm-6">
              <div class="widget-stat card" style={{ height: '140px' }}>
                <div class="card-body p-4">
                  <div class="media ai-icon">
                    <span class="mr-3 bgl-warning text-warning">
                      <img src={showIcon} width="50" height="50" />
                    </span>
                    <div class="media-body">
                      <p class="mb-1" style={{ textAlign: "center" }}>
                        Shows
                      </p>
                      <h4 class="mb-0" style={{ textAlign: "center" }}>
                        {parseInt(showsCount).toLocaleString("en-IN")}
                      </h4>
                    </div>
                    {/* <div class="media-body display">
                      <Link to="shows/list">
                        <p class="mb-1">See All</p>
                      </Link>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-lg-6 col-sm-6">
              <div class="widget-stat card" style={{ height: '140px' }}>
                <div class="card-body p-4">
                  <div class="media ai-icon">
                    <span class="mr-3 bgl-primary text-primary">
                      <img src={channelIcon} width="40" height="40" />
                    </span>
                    <div class="media-body">
                      <p class="mb-1" style={{ textAlign: "center" }}>
                        Channels
                      </p>
                      <h4 class="mb-0" style={{ textAlign: "center" }}>
                        {parseInt(channelsCount).toLocaleString("en-IN")}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {getPublisherConfigInfo("ecommerce_needed") != "True" && <div class="col-xl-3 col-lg-6 col-sm-6">
              <div class="widget-stat card" style={{ height: '140px' }}>
                <div class="card-body p-4">
                  <div class="media ai-icon">
                    <span class="mr-3 bgl-primary text-primary">
                      <img src={liveEventIcon} width="40" height="40" />
                    </span>
                    <div class="media-body">
                      <p class="mb-1" style={{ textAlign: "center" }}>
                        Live Events
                      </p>
                      <h4 class="mb-0" style={{ textAlign: "center" }}>
                        {/* {parseInt(channelsCount).toLocaleString("en-IN")} */}
                        0
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>}
          </>
        ) : null}
        {(getPublisherConfigInfo("linear_needed") == "True") ?
          <div class="col-xl-3 col-lg-6 col-sm-6">
            <div class="widget-stat card" style={{ height: '140px' }}>
              <div class="card-body  p-4">
                <div class="media ai-icon">
                  <span class="mr-3 bgl-danger text-danger">
                    <img src={live} width="50" height="50" />
                  </span>
                  <div class="media-body">
                    <p class="mb-1" style={{ textAlign: "center" }}>
                      Live Viewers
                    </p>
                    <h4 class="mb-0" style={{ textAlign: "center" }}>
                      {parseInt(
                        showLiveCount ? showLiveCount : 0
                      ).toLocaleString("en-IN")}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div> : null}

        {getPublisherConfigInfo("ecommerce_needed") != "True" &&
          external &&
          contentPartner ? (
          <div class="col-xl-3 col-lg-6 col-sm-6">
            <div class="widget-stat card" style={{ height: '140px' }}>
              <div class="card-body  p-4">
                <div class="media ai-icon">
                  <span class="mr-3 bgl-danger text-danger">
                    <img src={usersIcon} width="50" height="50" />
                  </span>
                  <div class="media-body">
                    <p class="mb-1" style={{ textAlign: "center" }}>
                      Total Users
                    </p>
                    <h4 class="mb-0" style={{ textAlign: "center" }}>
                      {parseInt(users).toLocaleString("en-IN")}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {getPublisherConfigInfo("svod_needed") == "True" && (
          <>
            {external && contentPartner ? (
              <div class="col-xl-3 col-lg-6 col-sm-6">
                <div class="widget-stat card" style={{ height: '140px' }}>
                  <div class="card-body p-4">
                    <div class="media ai-icon">
                      <span class="mr-3 bgl-primary text-primary">
                        <img src={activeSubIcon} width="50" height="50" />
                      </span>
                      <div class="media-body">
                        <p class="mb-1" style={{ textAlign: "center" }}>
                          Total Active&nbsp;Subscriptions
                        </p>
                        <h4 class="mb-0" style={{ textAlign: "center" }}>
                          {parseInt(totalActiveSubscriptions).toLocaleString(
                            "en-IN"
                          )}
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            {external && contentPartner ? (
              <div class="col-xl-3 col-lg-6 col-sm-6">
                <div class="widget-stat card" style={{ height: '140px' }}>
                  <div class="card-body p-4">
                    <div class="media ai-icon">
                      <span class="mr-3 bgl-warning text-warning">
                        <img src={newSubIcon} width="50" height="50" />
                      </span>
                      <div class="media-body">
                        <p class="mb-1" style={{ textAlign: "center" }}>
                          Monthly New Subscriptions
                        </p>
                        <h4 class="mb-0" style={{ textAlign: "center" }}>
                          {newSubscriptions}
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            {external && contentPartner ? (
              <div class="col-xl-3 col-lg-6 col-sm-6">
                <div class="widget-stat card" style={{ height: '140px' }}>
                  <div class="card-body p-4">
                    <div class="media ai-icon">
                      <span class="mr-3 bgl-success text-success">
                        <img src={renewalIcon} width="50" height="50" />
                      </span>
                      <div class="media-body">
                        <p class="mb-1" style={{ textAlign: "center" }}>
                          Monthly Renewal
                        </p>
                        <h4 class="mb-0" style={{ textAlign: "center" }}>
                          {parseFloat(montlyRenewal).toLocaleString("en-IN")}
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            {/* {getPublisherConfigInfo("ecommerce_needed") ? "":null}
        {getPublisherConfigInfo("ecommerce_needed") ? "":null} */}
            {external && contentPartner ? (
              <div class="col-xl-3 col-lg-6 col-sm-6">
                <div class="widget-stat card" style={{ height: '140px' }}>
                  <div class="card-body  p-4">
                    <div class="media ai-icon">
                      <span class="mr-3 bgl-danger text-danger">
                        <img src={monthlyRevenueIcon} width="50" height="50" />
                      </span>
                      <div class="media-body">
                        <p class="mb-1" style={{ textAlign: "center" }}>
                          Monthly Revenue
                        </p>
                        <h4 class="mb-0" style={{ textAlign: "center" }}>
                          {monthlyRevenue
                            ? "$ " +
                            parseFloat(monthlyRevenue).toLocaleString("en-IN")
                            : "$0"}
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </>
        )}

        {/* {getPublisherConfigInfo("ecommerce_needed") ? "<></>":null} */}

        {getPublisherConfigInfo("avod_needed") == "True" && (
          <>
            {contentPartner ? (
              <div class="col-xl-3 col-lg-6 col-sm-6">
                <div class="widget-stat card" style={{ height: '140px' }}>
                  <div class="card-body p-4">
                    <div class="media ai-icon">
                      <span class="mr-3 bgl-primary text-primary">
                        <img src={requestsIcon} width="50" height="50" />
                      </span>
                      <div class="media-body">
                        <p class="mb-1" style={{ textAlign: "center" }}>
                          Weekly Requests
                        </p>
                        <h4 class="mb-0" style={{ textAlign: "center" }}>
                          {requestCount}
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            {contentPartner ? (
              <div class="col-xl-3 col-lg-6 col-sm-6">
                <div class="widget-stat card" style={{ height: '140px' }}>
                  <div class="card-body p-4">
                    <div class="media ai-icon">
                      <span class="mr-3 bgl-warning text-warning">
                        <img src={impressionsIcon} width="50" height="50" />
                      </span>
                      <div class="media-body">
                        <p class="mb-1" style={{ textAlign: "center" }}>
                          Weekly Impressions
                        </p>
                        <h4 class="mb-0" style={{ textAlign: "center" }}>
                          {impressionsCount}
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            {contentPartner ? (
              <div class="col-xl-3 col-lg-6 col-sm-6">
                <div class="widget-stat card" style={{ height: '140px' }}>
                  <div class="card-body p-4">
                    <div class="media ai-icon">
                      <span class="mr-3 bgl-success text-success">
                        <img src={fillrateIcon} width="60" height="60" />
                      </span>
                      <div class="media-body">
                        <p class="mb-1" style={{ textAlign: "center" }}>
                          Weekly Fillrate
                        </p>
                        <h4
                          class="mb-0"
                          style={{ textAlign: "center" }}
                        >
                          {parseFloat(fillRate).toLocaleString("en-IN")}
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            {contentPartner ? (
              <div class="col-xl-3 col-lg-6 col-sm-6">
                <div class="widget-stat card" style={{ height: '140px' }}>
                  <div class="card-body  p-4">
                    <div class="media ai-icon">
                      <span class="mr-3 bgl-danger text-danger">
                        <img src={adRevenue} width="50" height="50" />
                      </span>
                      <div class="media-body">
                        <p
                          class="mb-1"
                          style={{ textAlign: "center" }}
                        >
                          Weekly Ad Revenue
                        </p>
                        <h4 class="mb-0" style={{ textAlign: "center" }}>
                          {revenue ? revenue : "$0"}
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </>
        )}

        {getPublisherConfigInfo("ecommerce_needed") && (
          <React.Fragment>
            <div class="col-xl-3 col-lg-6 col-sm-6">
              <div class="widget-stat card" style={{ height: '140px' }}>
                <div class="card-body  p-4">
                  <div class="media ai-icon">
                    <span class="mr-3 bgl-danger text-danger">
                      <img src={monthlyOrders} width="50" height="50" />
                    </span>
                    <div class="media-body">
                      <p class="mb-1" style={{ textAlign: "center" }}>
                        Monthly Orders
                      </p>
                      <h4 class="mb-0" style={{ textAlign: "center" }}>
                        {totalMonthlyOrders
                          ? parseFloat(totalMonthlyOrders).toLocaleString(
                            "en-IN"
                          )
                          : "0"}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-xl-3 col-lg-6 col-sm-6">
              <div class="widget-stat card" style={{ height: '140px' }}>
                <div class="card-body  p-4">
                  <div class="media ai-icon">
                    <span class="mr-3 bgl-danger text-danger">
                      <img src={monthlyRevenueIcon} width="50" height="50" />
                    </span>
                    <div class="media-body">
                      <p class="mb-1" style={{ textAlign: "center" }}>
                        Monthly Revenue
                      </p>
                      <h4 class="mb-0" style={{ textAlign: "center" }}>
                        {monthlyEcommerceRevenue
                          ? "$ " +
                          parseFloat(monthlyEcommerceRevenue).toLocaleString(
                            "en-IN"
                          )
                          : "$0"}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-xl-3 col-lg-6 col-sm-6">
              <div class="widget-stat card" style={{ height: '140px' }}>
                <div class="card-body  p-4">
                  <div class="media ai-icon">
                    <span class="mr-3 bgl-danger text-danger">
                      <img src={monthlyProductIcon} width="50" height="50" />
                    </span>
                    <div class="media-body">
                      <p class="mb-1" style={{ textAlign: "center" }}>
                        Monthly Product Views
                      </p>
                      <h4 class="mb-0" style={{ textAlign: "center" }}>
                        {monthlyProductViews}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-xl-3 col-lg-6 col-sm-6">
              <div class="widget-stat card" style={{ height: '140px' }}>
                <div class="card-body  p-4">
                  <div class="media ai-icon">
                    <span class="mr-3 bgl-danger text-danger">
                      <img src={weeklyOrders} width="50" height="50" />
                    </span>
                    <div class="media-body">
                      <p class="mb-1" style={{ textAlign: "center" }}>
                        Weekly Orders
                      </p>
                      <h4 class="mb-0" style={{ textAlign: "center" }}>
                        {ecomCounts.weekly_order
                          ? parseFloat(ecomCounts.weekly_order).toLocaleString(
                            "en-IN"
                          )
                          : "0"}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-xl-3 col-lg-6 col-sm-6">
              <div class="widget-stat card" style={{ height: '140px' }}>
                <div class="card-body  p-4">
                  <div class="media ai-icon">
                    <span class="mr-3 bgl-danger text-danger">
                      <img src={revenueIcon2} width="50" height="50" />
                    </span>
                    <div class="media-body">
                      <p class="mb-1" style={{ textAlign: "center" }}>
                        Weekly Revenue
                      </p>
                      <h4 class="mb-0" style={{ textAlign: "center" }}>
                        {ecomCounts.weekly_ecom_revenue
                          ? parseFloat(
                            ecomCounts.weekly_ecom_revenue
                          ).toLocaleString("en-IN")
                          : "$0"}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-xl-3 col-lg-6 col-sm-6">
              <div class="widget-stat card" style={{ height: '140px' }}>
                <div class="card-body  p-4">
                  <div class="media ai-icon">
                    <span class="mr-3 bgl-danger text-danger">
                      <img src={weeklyProductIcons} width="50" height="50" />
                    </span>
                    <div class="media-body">
                      <p class="mb-1" style={{ textAlign: "center" }}>
                        Weekly Product Views
                      </p>
                      <h4 class="mb-0" style={{ textAlign: "center" }}>
                        {weeklyProductView}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div class="col-xl-3 col-lg-6 col-sm-6">
              <div class="widget-stat card" style={{height: '140px'}}>
                <div class="card-body  p-4">
                  <div class="media ai-icon">
                    <span class="mr-3 bgl-danger text-danger">
                      <img src={merchantIcon} width="50" height="50" />
                    </span>
                    <div class="media-body">
                      <p class="mb-1" style={{ textAlign: "center" }}>
                        Merchants
                      </p>
                      <h4 class="mb-0" style={{ textAlign: "center" }}>
                        {ecomCounts.merchants}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            <div class="col-xl-3 col-lg-6 col-sm-6">
              <div class="widget-stat card" style={{ height: '140px' }}>
                <div class="card-body  p-4">
                  <div class="media ai-icon">
                    <span class="mr-3 bgl-danger text-danger">
                      <img src={totalProductIcons} width="50" height="50" />
                    </span>
                    <div class="media-body">
                      <p class="mb-1" style={{ textAlign: "center" }}>
                        Total Products
                      </p>
                      <h4 class="mb-0" style={{ textAlign: "center" }}>
                        {ecomCounts.products}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-xl-3 col-lg-6 col-sm-6">
              <div class="widget-stat card" style={{ height: '140px' }}>
                <div class="card-body  p-4">
                  <div class="media ai-icon">
                    <span class="mr-3 bgl-danger text-danger">
                      <img src={totalRevenueIcon} width="50" height="50" />
                    </span>
                    <div class="media-body">
                      <p class="mb-1" style={{ textAlign: "center" }}>
                        Total Revenue
                      </p>
                      <h4 class="mb-0" style={{ textAlign: "center" }}>
                        {totalRevenue
                          ? parseFloat(totalRevenue).toLocaleString("en-IN")
                          : "$0"}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-xl-3 col-lg-6 col-sm-6">
              <div class="widget-stat card" style={{ height: '140px' }}>
                <div class="card-body  p-4">
                  <div class="media ai-icon">
                    <span class="mr-3 bgl-danger text-danger">
                      <img src={revenueIcon} width="50" height="50" />
                    </span>
                    <div class="media-body">
                      <p class="mb-1" style={{ textAlign: "center" }}>
                        Total Orders
                      </p>
                      <h4 class="mb-0" style={{ textAlign: "center" }}>
                        {totalOrders
                          ? parseFloat(totalOrders).toLocaleString("en-IN")
                          : "$0"}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        )}

        {getPublisherConfigInfo("ecommerce_needed") == "True" ? (
          <div class="col-xl-3 col-lg-6 col-sm-6">
            <div class="widget-stat card" style={{ height: '140px' }}>
              <div class="card-body  p-4">
                <div class="media ai-icon">
                  <span class="mr-3 bgl-danger text-danger">
                    <img src={usersIcon} width="50" height="50" />
                  </span>
                  <div class="media-body">
                    <p class="mb-1" style={{ textAlign: "center" }}>
                      Total Users
                    </p>
                    <h4 class="mb-0" style={{ textAlign: "center" }}>
                      {parseInt(users).toLocaleString("en-IN")}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {external && contentPartner ? (
          <div class="col-xl-3 col-lg-6 col-sm-6">
            <div class="widget-stat card" style={{ height: '140px' }}>
              <div class="card-body p-4">
                <div class="media ai-icon">
                  <span class="mr-3 bgl-primary text-primary">
                    <img src={installedIcon} width="50" height="50" />
                  </span>
                  <div class="media-body">
                    <p class="mb-1" style={{ textAlign: "center" }}>
                      Daily Installed
                    </p>
                    <h4 class="mb-0" style={{ textAlign: "center" }}>
                      {installedCount}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {external && contentPartner ? (
          <div class="col-xl-3 col-lg-6 col-sm-6">
            <div class="widget-stat card" style={{ height: '140px' }}>
              <div class="card-body p-4">
                <div class="media ai-icon">
                  <span class="mr-3 bgl-warning text-warning">
                    <img src={activeUserIcon} width="50" height="50" />
                  </span>
                  <div class="media-body">
                    <p class="mb-1" style={{ textAlign: "center" }}>
                      Daily Active Users
                    </p>
                    <h4 class="mb-0" style={{ textAlign: "center" }}>
                      {parseInt(totalActiveUsers).toLocaleString("en-IN")}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {external && contentPartner ? (
          <div class="col-xl-3 col-lg-6 col-sm-6">
            <div class="widget-stat card" style={{ height: '140px' }}>
              <div class="card-body p-4">
                <div class="media ai-icon">
                  <span class="mr-3 bgl-success text-success">
                    <img src={totalViewersIcon} width="50" height="50" />
                  </span>
                  <div class="media-body">
                    <p class="mb-1" style={{ textAlign: "center" }}>
                      Daily Total Views
                    </p>
                    <h4 class="mb-0" style={{ textAlign: "center" }}>
                      {parseInt(totalViewers).toLocaleString("en-IN")}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {external && contentPartner ? (
          <div class="col-xl-3 col-lg-6 col-sm-6">
            <div class="widget-stat card" style={{ height: '140px' }}>
              <div class="card-body  p-4">
                <div class="media ai-icon">
                  <span class="mr-3 bgl-danger text-danger">
                    <img src={viewedMinutesIcon} width="50" height="50" />
                  </span>
                  <div class="media-body">
                    <p class="mb-1" style={{ textAlign: "center" }}>
                      Daily Viewed Minutes
                    </p>
                    <h4 class="mb-0" style={{ textAlign: "center" }}>
                      {parseFloat(
                        todayViewedMinutes ? todayViewedMinutes : 0
                      ).toLocaleString("en-IN")}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>

      <Row noGutters />
      {external ? (
        <Row noGutters>
          {!(getPublisherConfigInfo("ecom_publisher") == "True") &&
            contentPartner ? (
            <>
              {" "}
              <Col md={4} className="pr-lg-3">
                <PiChart data={popularVideos} title={"Weekly Top 5 Videos"} />
              </Col>
              <Col md={4} className="pr-lg-3">
                <PiChart data={popularShows} title={"Weekly Top 5 Shows"} />
              </Col>
              <Col md={4} className="pr-lg-3">
                <PiChart
                  data={usersPerDevice}
                  title={"Daily Users By Device"}
                />
              </Col>
            </>
          ) : null}
          {getPublisherConfigInfo("ecommerce_needed") && (
            <>
              <Col md={4} className="pr-lg-3 mt-2">
                <PiChart
                  data={ecomTopPurchased.products}
                  title={"Weekly Top 5 bought Products"}
                />
              </Col>
              <Col md={4} className="pr-lg-3 mt-2">
                <PiChart
                  data={ecomTopPurchased.merchants}
                  title={"Weekly Top 5 bought Merchants"}
                />
              </Col>
              <Col md={4} className="pr-lg-3 mt-2">
                <PiChart
                  data={ecomTopPurchased.categories}
                  title={"Weekly Top 5 bought Categories"}
                />
              </Col>

              <Col md={4} className="pr-lg-3 mt-2">
                <PiChart
                  data={ecomTopViews.products}
                  title={"Weekly Top 5 Viewed Products"}
                />
              </Col>
              <Col md={4} className="pr-lg-3 mt-2">
                <PiChart
                  data={ecomTopViews.merchants}
                  title={"Weekly Top 5 Viewed Merchants"}
                />
              </Col>
              <Col md={4} className="pr-lg-3 mt-2">
                <PiChart
                  data={ecomTopViews.categories}
                  title={"Weekly Top 5 Viewed Categories"}
                />
              </Col>
            </>
          )}
        </Row>
      ) : null}

      {getPublisherConfigInfo("ecommerce_needed") == "True" && (
        <React.Fragment>
          <Row noGutters>
            <Col md={6} className="pr-lg-3 mt-3">
              <PoinGraph
                xAxis={ecomOrdersGraph.xAxis}
                yAxis={ecomOrdersGraph.yAxis}
                title={"Weekly Orders"}
                dataName="Price: "
              />
            </Col>

            <Col md={6} className="pr-lg-3 mt-3">
              <PoinGraph
                xAxis={ecomGraphs.xAxis}
                yAxis={ecomGraphs.yAxis}
                title={"Weekly Product Views"}
                dataName="Views: "
              />
            </Col>
          </Row>
        </React.Fragment>
      )}
      {getPublisherConfigInfo("svod_needed") == "True" && contentPartner ? (
        <>
          <Row noGutters>
            <Col md={12} className="pr-lg-3">
              <div style={{ marginTop: "30px" }}>
                <Bargraph
                  data={subscriptonRevenue}
                  colors={productColors}
                  title={"Subscription Revenue(in  $ )"}
                  graphTitle={"Subscriptions"}
                />
              </div>
              <div />
            </Col>
          </Row>
          <Row noGutters>
            <Col md={4} className="pr-lg-3">
              <PoinGraph
                xAxis={totalSubscriptionsGraph.xAxis}
                yAxis={totalSubscriptionsGraph.yAxis}
                title={"Total Subscription"}
                dataName="Users Count"
              />
            </Col>
            <Col md={4} className="pr-lg-3">
              <PoinGraph
                dataName="Price"
                xAxis={newSubscriptionsGraph.xAxis}
                yAxis={newSubscriptionsGraph.yAxis}
                title={"New Subscription"}
              />
            </Col>
            <Col md={4} className="pr-lg-3">
              <PoinGraph
                dataName="Users Count"
                xAxis={cancellationsData.xAxis}
                yAxis={cancellationsData.yAxis}
                title={"Cancellations"}
              />
            </Col>
          </Row>
          <Row noGutters>
            <Col md={6} className="pr-lg-3">
              <Bargraph
                data={renewedSubscriptonRevenue}
                colors={productColors}
                title={"Renewed Subscription Revenue(in  $ )"}
                graphTitle={"Subscrip"}
              />
            </Col>
            <Col md={6} className="pr-lg-3">
              <Bargraph
                data={projectedSubscriptonRevenue}
                colors={productColors}
                title={"Projected Subscription Revenue(in  $ )"}
                graphTitle={"Subscripons"}
                legendTitle={"lskjdlks"}
              />
            </Col>
          </Row>
        </>
      ) : null}

      {getPublisherConfigInfo("avod_needed") == "True" ? (
        <>
          {" "}
          <Row noGutters style={{ marginTop: "1rem" }}>
            {contentPartner ? (
              <Col md={6} className="pr-lg-3">
                <PoinGraph
                  xAxis={activeUsersReportGraphData.xAxis}
                  yAxis={activeUsersReportGraphData.yAxis}
                  title={"Active Users"}
                  dataName="Users Count"
                />
              </Col>
            ) : null}

            {external && contentPartner ? (
              <Col md={6} className="pr-lg-3">
                <PoinGraph
                  xAxis={revenueReportGraphData.xAxis}
                  yAxis={revenueReportGraphData.yAxis}
                  title={"Revenue"}
                  dataName="Revenue($)"
                />
              </Col>
            ) : null}
          </Row>
        </>
      ) : null}

      {external ? null : (
        <Row noGutters style={{ marginTop: "1rem" }}>
          <Col md={6} className="pr-lg-3">
            <PoinGraph
              xAxis={revenueReportGraphData.xAxis}
              yAxis={revenueReportGraphData.yAxis}
              title={"Revenue"}
              dataName="Revenue($)"
            />
          </Col>
          <Col md={6} className="pr-lg-3">
            <PoinGraph
              xAxis={fillRateGraphData.xAxis}
              yAxis={fillRateGraphData.yAxisFilrate}
              title={"Fillrate"}
              dataName="Fillrate(%)"
            />
          </Col>
        </Row>
      )}
      {getPublisherConfigInfo("avod_needed") == "True" ? (
        <>
          <Row>
            <Col md={12} className="pr-lg-4">
              <Card>
                <FalconCardHeader
                  title={"Vod Analytics"}
                  light={false}
                  titleTag="h6"
                  className="pb-0"
                />
                <CardBody className="h-100">
                  <Tabs
                    id="controlled-tab-example"
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                  >
                    <Tab eventKey="Duration" title="Duration">
                      <PoinGraph
                        dataName="Viewed Duration in Minutes"
                        title={""}
                        xAxis={vodanalyticsReportGraphData.xAxis}
                        yAxis={vodanalyticsReportGraphData.yAxis}
                      />
                    </Tab>
                    <Tab eventKey="View" title="Views">
                      <PoinGraph
                        dataName="Views"
                        title={""}
                        xAxis={vodanalyticsViewReportGraphData.xAxisView}
                        yAxis={vodanalyticsViewReportGraphData.yAxisView}
                      />
                    </Tab>
                  </Tabs>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </>
      ) : null}
      {getPublisherConfigInfo("ecommerce_needed") && (
        <Row noGutters>
          <Col
            md={12}
            className="col-xxl-12 mb-3 pr-md-2"
            style={{ marginTop: "20px" }}
          >
            <Bargraph
              data={productsPerCategory}
              colors={productColors}
              title={"Weekly Products Per Category"}
              graphTitle={"Category"}
              legendTitle={"lskjdlks"}
            />
          </Col>
        </Row>
      )}
      {usersPerCounry && external && contentPartner ? (
        <Row noGutters>
          <Col
            md={12}
            className="col-xxl-12 mb-3 pr-md-2 col-lg-12"
            style={{ marginTop: "30px" }}
          >
            <Bargraph
              data={usersPerCounry}
              colors={productColors}
              title={"Daily Users Per Country"}
              graphTitle={"Users Per Country"}
              legendTitle={"lskjdlks"}
            />
          </Col>
        </Row>
      ) : null}
    </Fragment>
  );
};

export default Dashboard;

import { version } from "./config";

export const homeRoutes = {
  name: "Home",
  to: "/",
  exact: true,
  icon: "chart-pie",
  children: [
    {
      to: "/",
      name: "Dashboard",
      exact: true,
    },
  ],
};

export const dashboardRoutes = () => {
  let subMenu = {
    name: "CMS",
    to: "/shows/list",
    icon: "edit",
    children: [
      // { to: "/category/list", name: "Category", icon: "code-branch" },
      // { to: "/shows/list", name: "Shows", icon: "video" },
      // { to: "/videos/list", name: "Videos", icon: "video" },
      // { to: "/banner/list", name: "Banner", icon: "image" },
      // { to: "/newarrivals/order", name: "New Arrivals Order", icon: "video" },
    ],
  };
  // if (!getPublisherConfigInfo("ecommerce_login")) {
  if (localStorage.getItem("role_id") != 8 && localStorage.getItem("role_id") != 7) {
    subMenu.children.push({ to: "/category/list", name: "Genre", icon: "code-branch" });
  }
  if(localStorage.getItem("role_id") != 7){
  subMenu.children.push({ to: "/shows/list", name: "Shows", icon: "video" });
  subMenu.children.push({ to: "/videos/list", name: "Videos", icon: "video" });
  if(localStorage.getItem("role_id") != 8){
  subMenu.children.push({ to: "/podcast/list", name: "Podcast", icon: "audio" });
  }
  }
  if (localStorage.getItem("role_id") != 8 && localStorage.getItem("role_id") != 7) {
    subMenu.children.push({ to: "/banner/list", name: "Banner", icon: "image" });
    subMenu.children.push({ to: "/newarrivals/order", name: "New Arrivals Order", icon: "video" });
  }
  if (getPublisherConfigInfo("new_arrivals_video_order_needed") == "True" && localStorage.getItem("role_id") != 8 && localStorage.getItem("role_id") != 7) {
    subMenu.children.push({ to: "/newarrivals/episode/order", name: "New Arrivals Episode Order", icon: "video" });
  }
  if (getPublisherConfigInfo("film_of_the_day_needed") == "True" && localStorage.getItem("role_id") != 8 && localStorage.getItem("role_id") != 7) {
    subMenu.children.push({ to: "/filmoftheweek/order", name: "Film of the week", icon: "video" });
  }
  if (getPublisherConfigInfo("partner_needed") == "True") {
    subMenu.children.push({
      to: "/partner/list",
      name: "Partners ",
      icon: "user"
    });
  }
  if (getPublisherConfigInfo("community_needed") == "True") {
    subMenu.children.push({
      to: "/community/list",
      name: "Community ",
      icon: "user",
    });

  }
  if (getPublisherConfigInfo("news_needed") === "True" && localStorage.getItem("role_id") != 8) {
    subMenu.children.push({
      to: "/news/list",
      name: "News ",
      icon: "user",
    });
  }
   if (getPublisherConfigInfo("shorts_needed") === "True" && localStorage.getItem("role_id") != 8) {
    subMenu.children.push({
      to: "/shorts/list",
      name: "Shorts ",
      icon: "user",
    });
   }
  if (getPublisherConfigInfo("event_needed") === "True") {
    subMenu.children.push({
      to: "/event/list",
      name: "Events",
      icon: "user",
    });
  }
  if (localStorage.getItem("role_id") != 8) {
    subMenu.children.push({
      to: "/blog/list",
      name: "Blog",
      icon: "list",
    });
  }
  if (getPublisherConfigInfo("magazine_needeed") === "True"){ 
  subMenu.children.push({
    to: "/magazine/list",
    name: "Magazine",
    icon: "list",
  });}
  if(localStorage.getItem("role_id")!== 7 && localStorage.getItem("role_id") != 8){
  subMenu.children.push({
    to: "/transcoderstatus/table",
    name: "Transcoder Queue",
    icon: "table",
  });}
  if (getPublisherConfigInfo("recording_needed") === "True" && localStorage.getItem("role_id") != 8){ 
  subMenu.children.push({
    to: "/recordings/table",
    name: "Recordings",
    icon: "table",
  });
}

if (getPublisherConfigInfo("comments_needed") === "True"){ 
  subMenu.children.push({
    to: "/comments/list",
    name: "Comments",
    icon: "table",
  });}

  // subMenu.children.push({
  //   to: "/",
  //   name: "All Templates",
  //   icon: "list",
  // });
  if (getPublisherConfigInfo("playlist_needed") === "True"){
    subMenu.children.push({
      to: "/playlist_section/list",
      name: "Playlist",
      icon: "list",
    });
  }
  if(localStorage.getItem("role_id") === "5"){
    subMenu.children.push({
      to: "/language/mapping",
      name: "Language",
      icon: "table",
    });
  }
  if (getPublisherConfigInfo("b2b_needed") === "True"){
    subMenu.children.push({
      to: "/b2b/list",
      name: "Business to Business",
      icon: "list",
    });
  }
  return subMenu;
};
export const authenticationRoutes = {
  name: "Authentication",
  to: "/authentication",
  icon: "lock",
  children: [
    {
      to: "/authentication/basic",
      name: "Basic",
      children: [
        { to: "/authentication/basic/login", name: "Login" },
        { to: "/authentication/basic/logout", name: "Logout" },
        { to: "/authentication/basic/register", name: "Register" },
        {
          to: "/authentication/basic/forget-password",
          name: "Forgot password"
        },
        { to: "/authentication/basic/password-reset", name: "Reset password" },
        { to: "/authentication/basic/confirm-mail", name: "Confirm mail" },
        { to: "/authentication/basic/lock-screen", name: "Lock screen" },
        { to: "/authentication/basic/reset/:token", name: "Reset Password" },

      ]
    },
    {
      to: "/authentication/card",
      name: "Card",
      children: [
        { to: "/authentication/card/login", name: "Login" },
        { to: "/authentication/card/logout", name: "Logout" },
        { to: "/authentication/card/register", name: "Register" },
        { to: "/authentication/card/forget-password", name: "Forgot password" },
        { to: "/authentication/card/password-reset", name: "Reset password" },
        { to: "/authentication/card/confirm-mail", name: "Confirm mail" },
        { to: "/authentication/card/lock-screen", name: "Lock screen" },
        { to: "/authentication/card/reset/:token", name: "Reset Password" },
      ]
    },
    {
      to: "/authentication/split",
      name: "Split",
      children: [
        { to: "/authentication/split/login", name: "Login" },
        { to: "/authentication/split/logout", name: "Logout" },
        { to: "/authentication/split/register", name: "Register" },
        {
          to: "/authentication/split/forget-password",
          name: "Forgot password"
        },
        { to: "/authentication/split/password-reset", name: "Reset password" },
        { to: "/authentication/split/confirm-mail", name: "Confirm mail" },
        { to: "/authentication/split/lock-screen", name: "Lock screen" },
        { to: "/authentication/split/reset/:token", name: "Reset Password" },
      ]
    },
    {
      to: "/authentication/wizard",
      name: "Wizard"
    }
  ]
};

export const ECommerceRoutes = {
  name: "E commerce",
  to: "/e-commerce",
  icon: "cart-plus",
  children: [
    { to: "/e-commerce/products/list", name: "Product list" },
    { to: "/e-commerce/products/grid", name: "Product grid" },
    { to: "/e-commerce/product-details", name: "Product details" },
    { to: "/e-commerce/orders", name: "Orders" },
    { to: "/e-commerce/order-details", name: "Order details" },
    { to: "/e-commerce/customers", name: "Customers" },
    { to: "/e-commerce/shopping-cart", name: "Shopping cart" },
    { to: "/e-commerce/checkout", name: "Checkout" },
    { to: "/e-commerce/favourite-items", name: "Favourite items" }
  ]
};

export const pageRoutes = {
  name: "Pages",
  to: "/pages",
  icon: "copy",
  children: [
    { to: "/pages/activity", name: "Activity" },
    { to: "/pages/associations", name: "Associations" },
    { to: "/pages/billing", name: "Billing" },
    { to: "/pages/customer-details", name: "Customer details" },
    { to: "/pages/event-detail", name: "Event detail" },
    { to: "/pages/event-create", name: "Event create" },
    { to: "/schedule", name: "Linear" },
    { to: "/pages/faq", name: "Faq" },
    { to: "/pages/invoice", name: "Invoice" },
    { to: "/pages/invite-people", name: "Invite people" },
    { to: "/pages/notifications", name: "Notifications" },
    { to: "/pages/people", name: "People" },
    { to: "/pages/pricing", name: "Pricing" },
    { to: "/pages/pricing-alt", name: "Pricing alt" },
    { to: "/pages/profile", name: "Profile" },
    { to: "/pages/settings", name: "Settings" },
    { to: "/pages/starter", name: "Starter" },
    { to: "/linearChannel/list", name: "Linear Channel" },
    { to: "/linearEvent/list", name: "Linear Event" },

    {
      to: "/errors",
      name: "Errors",
      children: [
        { to: "/errors/404", name: "404" },
        { to: "/errors/500", name: "500" }
      ]
    }
  ]
};
export const widgetsRoutes = {
  name: "Widgets",
  to: "/widgets",
  exact: true,
  icon: "poll",
  badge: {
    text: `New`,
    color: "soft-success"
  }
};

export const chatRoutes = {
  name: "Chat",
  to: "/chat",
  exact: true,
  icon: "comments"
};

export const emailRoutes = {
  name: "Email",
  to: "/email",
  icon: "envelope-open",
  children: [
    { to: "/email/inbox", name: "Inbox" },
    { to: "/email/email-detail", name: "Email detail" },
    { to: "/email/compose", name: "Compose" }
  ]
};

export const documentationRoutes = {
  name: "Documentation",
  to: "/documentation",
  exact: true,
  icon: "book"
};

export const changelogRoutes = {
  name: "ChangeLog",
  to: "/changelog",
  exact: true,
  icon: "code-branch",
  badge: {
    text: `v${version}`,
    color: "soft-primary"
  }
};

export const componentRoutes = {
  name: "Clients",
  to: "/components",
  icon: "puzzle-piece",
  children: [
    { to: "/components/alerts", name: "Alerts" },
    { to: "/components/accordions", name: "Accordions" },
    { to: "/components/avatar", name: "Avatar" },
    { to: "/components/badges", name: "Badges" },
    { to: "/components/backgrounds", name: "Backgrounds" },
    { to: "/components/breadcrumb", name: "Breadcrumb" },
    { to: "/components/buttons", name: "Buttons" },
    { to: "/components/cards", name: "Cards" },
    { to: "/components/collapses", name: "Collapses" },
    {
      to: "/components/carousel",
      name: "Carousel",
      badge: {
        text: `New`
      }
    },
    { to: "/components/dropdowns", name: "Dropdowns" },
    { to: "/components/forms", name: "Forms" },
    { to: "/components/listgroups", name: "List groups" },
    { to: "/components/modals", name: "Modals" },
    { to: "/components/navs", name: "Navs" },
    { to: "/components/navbars", name: "Navbars" },
    {
      to: "/components/navbar-top",
      name: "Navbar Top",
      badge: {
        text: `New`
      }
    },
    { to: "/components/pageheaders", name: "Page headers" },
    { to: "/components/paginations", name: "Paginations" },
    { to: "/components/popovers", name: "Popovers" },
    { to: "/components/progress", name: "Progress" },
    {
      to: "/components/sidepanel",
      name: "Sidepanel",
      badge: {
        text: "New"
      }
    },
    {
      to: "/components/spinners",
      name: "Spinners",
      badge: {
        text: `New`
      }
    },

    { to: "/components/tables", name: "Tables" },
    { to: "/components/clientList", name: "Client List" },
    { to: "/components/clientAdd", name: "Client Add" },
    { to: "/components/tooltips", name: "Tooltips" }
  ]
};

export const pluginRoutes = {
  name: "Plugins",
  to: "/plugins",
  icon: "plug",
  children: [
    { to: "/plugins/bulk-select", name: "Bulk select" },
    {
      to: "/plugins",
      name: "Chart",
      children: [
        { to: "/plugins/chart", name: "Chart Js" },
        { to: "/plugins/echarts", name: "Echarts" }
      ]
    },
    { to: "/plugins/countup", name: "Countup" },
    { to: "/plugins/code-highlight", name: "Code Highlight" },

    { to: "/plugins/datetime", name: "Datetime" },
    { to: "/plugins/dropzone", name: "Dropzone" },
    { to: "/plugins/emoji-mart", name: "Emoji Mart" },
    { to: "/plugins/fontawesome", name: "Fontawesome" },

    { to: "/plugins/image-lightbox", name: "Image lightbox" },
    { to: "/plugins/lottie", name: "Lottie" },
    {
      to: "/plugins",
      name: "Map",
      children: [
        { to: "/plugins/leaflet-map", name: "Leaflet map" },
        { to: "/plugins/google-map", name: "Google map" },
        { to: "/plugins/echart-map", name: "Echart Map" }
      ]
    },
    { to: "/plugins/plyr", name: "Plyr" },
    { to: "/plugins/progressbar", name: "Progressbar" },
    { to: "/plugins/react-hook-form", name: "React Hook Form" },
    { to: "/plugins/select", name: "Select" },
    { to: "/plugins/slick-carousel", name: "Slick Carousel" },
    { to: "/plugins/scroll-bar", name: "Scroll Bar" },
    { to: "/plugins/toastify", name: "Toastify" },
    { to: "/plugins/typed", name: "Typed" },
    { to: "/plugins/wysiwyg", name: "WYSIWYG editor" }
  ]
};

export const utilityRoutes = {
  name: "Utilities",
  to: "/utilities",
  icon: ["fab", "hotjar"],
  children: [
    { to: "/utilities/borders", name: "Borders" },
    { to: "/utilities/clearfix", name: "Clearfix" },
    { to: "/utilities/closeIcon", name: "Close icon" },
    { to: "/utilities/colors", name: "Colors" },
    { to: "/utilities/display", name: "Display" },
    { to: "/utilities/embed", name: "Embed" },
    { to: "/utilities/figures", name: "Figures" },
    { to: "/utilities/flex", name: "Flex" },
    { to: "/utilities/grid", name: "Grid" },
    { to: "/utilities/sizing", name: "Sizing" },
    { to: "/utilities/spacing", name: "Spacing" },
    { to: "/utilities/stretchedLink", name: "Stretched link" },
    { to: "/utilities/typography", name: "Typography" },
    { to: "/utilities/verticalAlign", name: "Vertical align" },
    { to: "/utilities/visibility", name: "Visibility" }
  ]
};

export const categoryRoutes = {
  name: "Genre",
  to: "/category/list",
  icon: "code-branch",
  children: [
    { to: "/category/list", name: "List" },
    // { to: '/category/add', name: 'Add' },
  ],
};

export const channelRoutes = {
  name: "Channel",
  to: "/channel/list",
  icon: "list",
  children: [
    { to: "/channel/list", name: "List" }
    // { to: '/category/add', name: 'Add' },
  ]
};
export const feedRoutes = {
  name: "Feed Management",
  to: "/feed/list",
  icon: "list",
  children: [
    { to: "/feed/list", name: "List" },
    // { to: '/category/add', name: 'Add' },
  ],
};

export const appRoutes = {
  name: "Apps",
  to: "/app/list",
  icon: "list",
  children: [
    { to: "/app/list", name: "List" }
    // { to: '/category/add', name: 'Add' },
  ]
};


export const adTextRoutes = {
  name: "Ad Text",
  to: "/Adtext/list",
  icon: "list",
  children: [{
    to: "/Adtext/list", name: "List"
  },
  ],
};
export const magazineUpdateRoutes = {
  name: "Magazine",
  to: "/magazine/list",
  icon: "list",
  children: [{
    to: "/magazine/list", name: "List"
  },
  ],
};

export const subscriptionRoutes = {
  name: "Subscription",
  to: "/subscription/list",
  icon: "book",
  children: [
    { to: "/subscription/list", name: "List" }
    // { to: '/category/add', name: 'Add' },
  ]
};

export const showRoutes = {
  name: "Shows",
  to: "/shows/list",
  icon: "image",
  children: [
    { to: "/shows/list", name: "List" }
    // { to: '/shows/add', name: 'Add' },
  ]
};

export const videoRoutes = {
  name: "Videos",
  to: "/videos/list",
  icon: "video",
  children: [
    { to: "/videos/list", name: "List" }
    // { to: '/videos/add', name: 'Add' },
  ]
};

export const campaignRoutes = {
  name: "Campaigns",
  to: "#",
  icon: ["fab", "hotjar"],
  children: [
    { to: "#", name: "Campaign List" },
    {
      name: "Report",
      to: "#",
      icon: "poll",
      children: [{ to: "#", name: "Campaign " }]
    }
  ]
};

export const demandRoutes = {
  name: "Demands",
  to: "/demandSource/list",
  icon: "book",
  children: [
    { to: "/demandSource/list", name: "Demand List" },
    { to: "/demandPartner/list", name: "Demand Partner List" },
    { to: "/demandMapping", name: "Demand Mapping" },
    {
      name: "Report",
      to: "/demandSource/list",
      icon: "poll",
      children: [
        { to: "/demandReport", name: "Demand " },
        { to: "/report/revenues_report", name: "Revenue " }
      ]
    }
  ]
};

export const reportRoutes = {
  name: "Reports",
  to: "/report/cancel_report",
  icon: "poll",
  children: []
};

export const scheduleRoutes = {
  name: "Live",
  to: "/schedule",
  icon: "copy",
  children: [
    // { to: "/schedule", name: "Schedule" },
    { to: "/linearChannel/list", name: "Fast Channel" },
    { to: "/linearEvent/list", name: "Live Event" },
    // { to: "/schedule-feed", name: "Linear Feed Schedule" },
    { to: "/filler-video/list", name: "Filler Video" },
  ],

};

export const demandReports = {
  name: "Reports",
  to: "/demandSource/list",
  icon: "poll",
  children: [
    { to: "/demandReport", name: "Demand " },
    { to: "/report/revenues_report", name: "Revenue " },
    { to: "/campaignReport", name: "Campaign " }
  ]
};

export const adsText = {
  name: "Ads Text",
  to: "/adtext/list",
  icon: "list",
  children: [{
    to: "/adtext/list",
    name: "Ad Text",
    icon: "list",
  }]
}

export const demands = {
  name: "Demands",
  to: "/demandSource/list",
  icon: "book",
  children: [
    { to: "/demandPartner/list", name: "Partners" },
    { to: "/demandSource/list", name: "Demands" },
    { to: "/demandMapping", name: "Mapping" }
  ]
};

export const campaign = {
  name: "Campaigns",
  to: "#",
  icon: ["fab", "hotjar"],
  children: [
    { to: "/advertiser/list", name: "Advertisers" },
    { to: "/campaign/list", name: "Campaigns" }
    // {
    //   name: "Report",
    //   to: "#",
    //   icon: "poll",
    //   children: [{ to: "#", name: "Campaign " }]
    // }
  ]
};

export const avod = {
  name: "AVOD",
  to: "#",
  icon: "poll",
  children: [
    {
      name: "Demands",
      to: "/demandSource/list",
      icon: "book",
      children: [
        { to: "/demandSource/list", name: "Demand List" },
        { to: "/demandPartner/list", name: "Demand Partner List" },
        { to: "/demandMapping", name: "Demand Mapping" }
      ]
    },
    {
      name: "Campaigns",
      to: "#",
      icon: ["fab", "hotjar"],
      children: [
        { to: "/components/advertiserlist", name: "Advertiser" },
        { to: "/components/campaignlist", name: "Campaign" }
        // {
        //   name: "Report",
        //   to: "#",
        //   icon: "poll",
        //   children: [{ to: "#", name: "Campaign " }]
        // }
      ]
    },
    {
      name: "Reports",
      to: "/demandSource/list",
      icon: "poll",
      children: [
        { to: "/demandReport", name: "Demand " },
        { to: "/report/revenues_report", name: "Revenue " },
        { to: "#", name: "Campaign " }
      ]
    },
    {
      name: "Ads Text",
      to: "/adtext/list",
      icon: "list",
      children: [{
        to: "/adtext/list",
        name: "Ad Text",
        icon: "list",
      }]
    }
  ]
};

// export const svod = {
//   name: "SVOD",
//   to: "/subscription",
//   icon: "poll",
//   children: [
//     { to: "/report/cancel_report", name: "Cancellation " },
//     { to: "/report/refund_report", name: "Refund " },
//     { to: "/report/renewal_report", name: "Renewal " },
//     { to: "/report/non_renewal_report", name: "Non Renewal " },
//     { to: "/report/failed_renew_report", name: "Failed Renewal " },
//     { to: "/report/subscribed_users", name: "Subscribed Users" },
//     { to: "/report/upgrade_report", name: "Upgrade " },
//     { to: "/report/revenue_report", name: "Revenue " },
//     { to: "/report/mis_match_report", name: "Mismatch(Hist-Actual) " },
//     { to: "/report/active_subscriptions", name: "Active Subscriptions" }
//   ]
// };

export const marketing = () => {
  let subMenu = {
    name: "Marketing",
    to: "#",
    icon: "poll",
    children: [],
  };

  subMenu.children.push({ to: "/notifications", name: "Push Notifications" });

  return subMenu;
}

export const configSettings = () => {
  let subMenu = {
    name: "Settings",
    to: "#",
    icon: "poll",
    children: [],
  };

  subMenu.children.push({ to: "/config/add", name: "CONFIG " });
  subMenu.children.push({ to: "/gizmott/config/add", name: "GIZMOTT CONFIG " });

  return subMenu;
}
export const contentPartnerAvod = () => {
  let subMenu = {
    name: "AVOD",
    to: "#",
    icon: "poll",
    children: [],
  };

  subMenu.children.push({ to: "/report/revenues_report", name: "Revenue " });

  return subMenu;
};
export const svod = () => {
  let subMenu = {
    name: "SVOD",
    to: "/subscription",
    icon: "poll",
    children: []
  };
  if (getPublisherConfigInfo("content_partner_needed") == "True") {
    subMenu.children.push({ to: "/report/content_partner_revenue_report", name: "Content Partner Revenue Report" });

  }
  if (role_id != 8) {
    subMenu.children.push({ to: "/report/cancel_report", name: "Cancellation " });
    subMenu.children.push({ to: "/report/refund_report", name: "Refund " });
    subMenu.children.push({
      to: "/report/non_renewal_report",
      name: "Non Renewal "
    });
    if (getPublisherConfigInfo("failed_renewal_report_needed") == "True") {
      subMenu.children.push({
        to: "/report/failed_renew_report",
        name: "Failed Renewal "
      });
    }
    subMenu.children.push({
      to: "/report/subscribed_users",
      name: "Subscribed Users"
    });
    if (getPublisherConfigInfo("upgrade_report_needed") == "True") {
      subMenu.children.push({ to: "/report/upgrade_report", name: "Upgrade " });
    }
    subMenu.children.push({ to: "/report/revenue_report", name: "Revenue " });
    (getPublisherConfigInfo('coupon_needed') === 'True' && subMenu.children.push({ to: "/report/coupon_revenue_report", name: "Coupon Revenue " }));
    if (getPublisherConfigInfo("mismatch_report_needed") == "True") {
      subMenu.children.push({
        to: "/report/mis_match_report",
        name: "Mismatch(Hist-Actual) "
      });
    }
    subMenu.children.push({ to: "/report/renewal_report", name: "Renewal " },);
  }
  return subMenu;
};

export const otherReports = () => {
  let subMenu = {
    name: "Analytics",
    to: "/subscription",
    icon: "poll",
    children: [

      // { to: "/report/totalviewers_report", name: "Video Viewers " },


      // { to: "/report/order", name: "I-Order Report" }
    ],
  };
  if (role_id != 8) {
    subMenu.children.push({ to: "/report/userdetail_report", name: "User Details " },
      { to: "/report/total_active_user_report", name: "Active Users " },
      { to: "/report/inactive_users", name: "Inactive Users " },
      { to: "/report/app_engage", name: "App Engagement " },
      { to: "/report/totaluser_report", name: "New Users" });

  }
  (getPublisherConfigInfo('data_usage_report_needed') === 'True' && localStorage.getItem("role_id") !=8 && subMenu.children.push({ to: "/report/datausage", name: "Data Usage" }))
  // subMenu.children.push({ to: "/report/affiliate", name: "Affiliate" });
  // subMenu.children.push({ to: "/report/affiliate_coupon", name: "Affiliate Coupon" });
  subMenu.children.push({ to: "/report/vodanalytics_report", name: "VOD Analytics " });
  if (getPublisherConfigInfo("shorts_needed") === "True" && localStorage.getItem("role_id") != 8) {
  subMenu.children.push({ to: "/report/shortsanalytics_report", name: "Shorts Analytics " });
  }
  if(role_id == 14){
    subMenu.children.push({ to: "/report/donation/direct-debit", name:"Direct Debit" });
    subMenu.children.push({ to: "/report/donation", name: "Donation" });
  }

  if (role_id != 8 && !getPublisherConfigInfo('live_analytics_report_needed')) {
    subMenu.children.push({ to: "/report/liveviews_report", name: "Live Analytics " },
      { to: "/report/category_video", name: "Genre Analytics" });

  }

  if (role_id != 8) {
    subMenu.children.push({ to: "/report/completed_video", name: "Video Engagement" });
    (getPublisherConfigInfo('coupon_needed') === 'True' && subMenu.children.push({ to: "/report/coupon", name: "Coupon" }))
  }
  (getPublisherConfigInfo('live_analytics_report_needed') === 'True' && subMenu.children.push({ to: "/report/live-analytics-report", name: "Live Analytics" }))
  if (role_id != 8) {
    subMenu.children.push(
      { to: "/report/error_report", name: "Error Report" },
      //{ to: "/report/all_report", name: "Combined Report" }
      { to: "/report/newsletter_report", name: "Newsletters " },
      { to: "/report/favorite_report", name: "Favorites " },
      { to: "/report/ratings_report", name: "Ratings " },
      { to: "/report/event_analytics", name: "Event Analytics" }
      )
  }
  if (getPublisherConfigInfo("news_needed") === "True" && localStorage.getItem("role_id") != 8) {
    subMenu.children.push({ to: "/report/news_analytics", name: "News Analytics" });
  }
  return subMenu;
};

export const tvod = () => {
  let subMenu = {
    name: "TVOD",
    to: "#",
    icon: "poll",
    children: [

    ],
  };

  subMenu.children.push({ to: "/report/pay_per_view_report", name: "Pay Per View" });
  if (localStorage.getItem("role_id") != 8) {
    subMenu.children.push({ to: "/report/usage", name: "Usage " });
  }
  return subMenu;
};

// export const operations = () => {
//   let subMenu = {
//     name: "Operations",
//     to: "#",
//     icon: "plug",
//     children: []
//   };

//   if (getPublisherConfigInfo("reset_device_needed") == "True") {
//     subMenu.children.push({ to: "/reset/device", name: "Reset Device" });
//   }
//   // if(getPublisherConfigInfo('reset_password_needed') == 'True') {
//   // subMenu.children.push({ to: "/reset/password", name: "Reset Password" });
//   // }
//   // if (getPublisherConfigInfo("find_users_needed") == "True") {
//   //   subMenu.children.unshift({
//   //     to: "/operations/UserManagment",
//   //     name: "Find Users"
//   //   });
//   // }

//   if (getPublisherConfigInfo("active_subscriptions_needed") == "True") {
//     subMenu.children.push({
//       to: "/report/active_subscriptions",
//       name: "Active Subscriptions"
//     });
//   }

//   // if (localStorage.getItem("role_id") != 8) {
//   //   subMenu.children.push({
//   //     to: "/freeuser/add",
//   //     name: "Free User ",
//   //     icon: "user",
//   //   });
//   // }

//   return subMenu;
// };

export const userManagement = () => {
  let subMenu = {
    name: "User Management",
    to: "#",
    icon: "plug",
    children: []
  };
  subMenu.children.push({ to: "/user/password/reset", name: "Reset Password" });
  if (getPublisherConfigInfo("find_users_needed") == "True") {
    subMenu.children.unshift({
      to: "/operations/UserManagment",
      name: "Find Users"
    });
  }

  if (localStorage.getItem("role_id") != 8) {
    subMenu.children.push({
      to: "/freeuser/add",
      name: "Free User ",
      icon: "user",
    });
  }

  if (getPublisherConfigInfo("reset_device_needed") == "True") {
    subMenu.children.push({ to: "/device/reset", name: "Reset Device" });
  }
  if (getPublisherConfigInfo("active_subscriptions_needed") == "True") {
    subMenu.children.push({
      to: "/report/active_subscriptions",
      name: "Active Subscriptions"
    });
  }
  return subMenu;
};

export const master = () => {
  let subMenu = {
    name: "Master",
    to: "#",
    icon: "lock",
    children:[]
    // children: [{ to: "/app/list", name: "Apps", icon: "list" }]
  };
  if(localStorage.getItem("role_id") != 7 && localStorage.getItem("superadmin") ){subMenu.children.push({ to: "/app/list", name: "Apps", icon: "list" })}
  if (getPublisherConfigInfo("content_partner_needed") == "True") {
    subMenu.children.push({ to: "/content/partner/list", name: "Content Partner", icon: "list" });
  }


  if (getPublisherConfigInfo("subscriptions_needed") == "True" ) {
    subMenu.children.push({
      to: "/subscription/list",
      name: "Subscriptions",
      icon: "list"
    });
  }
  getPublisherConfigInfo('coupon_needed') === 'True' && subMenu.children.push({
    to: "/coupon/list",
    name: "Coupons",
    icon: "list",
  });
  if(localStorage.getItem("role_id") != 7 ){
  if(localStorage.getItem("superadmin")){
    subMenu.children.push({ to: "/channel/list", name: "Channel", icon: "list" });
  }
  subMenu.children.push({
    to: "/feed/list",
    name: "Feed Management",
    icon: "list",
  });
  subMenu.children.push({
    to: "/adtext/list",
    name: "Ad Text",
    icon: "list",
  });
 }
  // subMenu.children.push({
  //   to: "/magazine/list",
  //   name: "Magazine",
  //   icon: "list",
  // });



  if (getPublisherConfigInfo("screening_needed")) {
    subMenu.children.push({
      to: "/screening/list",
      name: "Screening Users",
      icon: "list",
    });
  }
  


  if (localStorage.getItem("role_id")== 7) {
    subMenu.children.push({
      to: "/super-admin/publisher-list",
      name: "Publishers",
      icon: "list",
    });
  }
  return subMenu;
};
export const AnalyticsRoutes = () =>{
  let subMenu = {
    name: "Analytics",
    to: "#",
    icon: "poll",
    children: []
  };
  if (localStorage.getItem("role_id")== 7) {
    
  subMenu.children.push({ to: "/report/datausage", name:"Data Usage" });
}
  return subMenu;

}
export const Ecommerce = () => {
  return {
    name: "Flixbuy",
    to: "/report/",
    icon: "poll",
    children: [
      { to: "/ecommerce/category/list", name: "Category", icon: "video" },
      { to: "/ecommerce/video/list", name: "Video", icon: "video" },
      { to: "/ecommerce/show/list", name: "Show", icon: "video" },
      { to: "/playlist/list", name: "Playlist", icon: "list" },
      { to: "/ecom/schedule", name: "Schedule", icon: "list" },
      { to: "/merchant/list", name: "Merchant", icon: "list" },
      { to: "/product/list", name: "Products", icon: "code-branch" },
      { to: "/influencer/list", name: "Influencer", icon: "list" },
      { to: "/ecommerce/banner/list", name: "Banner", icon: "list" },
      { to: "/ecommerce/influencer", name: "Influencer Event", icon: "list" },
      { to: "/flixbuy/live", name: "Live Event", icon: "list" },
      { to: "/salesChannel/list", name: "Sales channel", icon: "video" },
      { to: "/post/list", name: "Post", icon: "video" },
      { to: "/settings", name: "Settings", icon: "video" }
      // { to: "/influencer/list", name: "I -Influencer", icon: "list" },
      // { to: "/influencer_register", name: "Influencer_Register", icon: "list" }
    ]
  }
}

export const EcommerceAnalytics = () => {
  return {
    name: "E-commerce Analytics",
    to: "/report/",
    icon: "poll",
    children: [
      { to: "/report/order", name: "Order Report" },
      { to: "/report/ecom/productview", name: "Product View Report" },
      { to: "/report/ecom/category_productview", name: "Product View Category Report" },
      // { to: "/report/ecom/region_productview", name: "Product View Region Report" },
      { to: "/report/ecom/event_report", name: "Event Report" },
      { to: "/report/ecom/user_cart_report", name: "User Cart Report" },
      { to: "/report/ecom/product_video_session", name: "Product Video Session" }
    ]
  }
};

export const affiliateRoutes = {
  name: "Affiliate",
  to: "/",
  exact: true,
  icon: "chart-pie",
  children: [
    {
      to: "/affiliate/list",
      name: "Affiliate",
      exact: true,
    },
    // {
    //   to: "/creatives/list",
    //   name: "Creatives",
    //   exact: true,
    // },
{ to: "/report/affiliate", name: "Affiliate Report", exact: true, },
// { to: "/report/affiliate_coupon", name: "Affiliate Coupon Report", exact: true, },
// { to: "/network/list", name:"Network", exact:true, }
  ],
};


export const FinanceRoute = () =>{
  let subMenu = {
    name: "Finance",
    to: "#",
    icon: "poll",
    children: []
  };
  subMenu.children.push({ to: "/report/donation/direct-debit", name:"Direct Debit" });
  subMenu.children.push({ to: "/report/donation", name: "Donation" });
  return subMenu;
}

const getPublisherConfigInfo = keyName => {
  let response = false;
  if(localStorage.getItem("publisherConfig") && (typeof localStorage.getItem("publisherConfig") !== 'undefined' && (localStorage.getItem("publisherConfig") !=='undefined'))){
  var publisherConfig = JSON.parse(localStorage.getItem("publisherConfig"));
  if (publisherConfig &&  publisherConfig?.length>0) {
    let configInfo = publisherConfig.filter(i => keyName == i.key_name);
    response = configInfo[0] && configInfo[0].key_value;
    return response;
  }
  }
  return response;
};

let mainRoutes = [];

const role_id = localStorage.getItem("role_id");

if (role_id == 7) {
  reportRoutes.children.unshift({ to: "/superReport", name: "Super Report" });
  mainRoutes = [homeRoutes, dashboardRoutes(),configSettings()];
} 
else if(role_id ==13){
  mainRoutes=[FinanceRoute()];
}
else if(role_id ==14){
  mainRoutes = [];
  mainRoutes.push(homeRoutes);
  mainRoutes.push(master());
  mainRoutes.push(dashboardRoutes());
  // mainRoutes.push(operations());
  if (getPublisherConfigInfo("linear_needed") == "True") {
    mainRoutes.push(scheduleRoutes);
  }
  if (getPublisherConfigInfo("tvod_needed") == "True") {
    mainRoutes.push(tvod());
  }
  if (getPublisherConfigInfo("avod_needed") == "True") {
    mainRoutes.push(avod);
    mainRoutes.push(contentPartnerAvod());
  }
  if (getPublisherConfigInfo("svod_needed") == "True") {
    mainRoutes.push(svod());
  }
  //if(getPublisherConfigInfo("ecommerce_needed") == "True"){
  mainRoutes.push(Ecommerce());
  //}
  //if(getPublisherConfigInfo("ecommerce_needed") == "True"){
  mainRoutes.push(EcommerceAnalytics());
  // } 
  // mainRoutes.push(configSettings());
  mainRoutes.push(otherReports());
  mainRoutes.push(FinanceRoute());
}
else {
  mainRoutes = [];
  mainRoutes.push(homeRoutes);
  mainRoutes.push(master());
  mainRoutes.push(AnalyticsRoutes())
  mainRoutes.push(dashboardRoutes());
  // mainRoutes.push(operations());
  if (getPublisherConfigInfo("linear_needed") == "True") {
    mainRoutes.push(scheduleRoutes);
  }
  if (getPublisherConfigInfo("tvod_needed") == "True") {
    mainRoutes.push(tvod());
  }
  if (getPublisherConfigInfo("avod_needed") == "True") {
    mainRoutes.push(avod);
    mainRoutes.push(contentPartnerAvod());
  }
  if (getPublisherConfigInfo("svod_needed") == "True") {
    mainRoutes.push(svod());
  }
  //if(getPublisherConfigInfo("ecommerce_needed") == "True"){
  mainRoutes.push(Ecommerce());
  //}
  //if(getPublisherConfigInfo("ecommerce_needed") == "True"){
  mainRoutes.push(EcommerceAnalytics());
  // } 
  if(localStorage.getItem("superadmin")){
  mainRoutes.push(configSettings());
  }
  mainRoutes.push(otherReports());
}

export default mainRoutes;

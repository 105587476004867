import React from "react";

import dashboardImg1 from '../../assets/img/dashboard-png/adserver/Ad-Server-Dashboard-1.jpg';
import dashboardImg2 from '../../assets/img/dashboard-png/adserver/Ad-Server-Dashboard-2.jpg';
import dashboardImg3 from '../../assets/img/dashboard-png/adserver/Ad-Server-Dashboard-3.jpg';
import dashboardImg4 from '../../assets/img/dashboard-png/adserver/Ad-Server-Dashboard-4.jpg';
import dashboardImg5 from '../../assets/img/dashboard-png/adserver/Ad-Server-Dashboard-5.jpg';



const Dashboard = (props) => {
  return (<div className="h-100 mt-6">
    <img src={dashboardImg1} className="w-100" />
    <img src={dashboardImg2} className="w-100" />
    <img src={dashboardImg3} className="w-100" />
    <img src={dashboardImg4} className="w-100" />
    <img src={dashboardImg5} className="w-100" />
  </div>)
};

export default Dashboard;
